import { createSlice } from "@reduxjs/toolkit";
import { GetRecommendersParams, Recommender } from "../../types";

const initialState = {
  selectedRecommendation: {} as Recommender,
  filters: {} as GetRecommendersParams,
};

export const recommendationSlice = createSlice({
  name: "recommendation",
  initialState,
  reducers: {
    selectRecommendation: (state, { payload }) => {
      state.selectedRecommendation = payload;
      return state;
    },
    setFilters: (state, { payload }) => {
      state.filters = payload;
      return state;
    },
    resetAll: (state) => {
      return initialState;
    },
  },
});

export const { selectRecommendation, setFilters, resetAll } =
  recommendationSlice.actions;

export default recommendationSlice.reducer;
