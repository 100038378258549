import {
  Button,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import "./styles.sass";
import Logo from "../../global/images/logo.svg";
import LoginForm from "../../components/LoginForm";
import SignupForm from "../../components/SignupForm";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useConfirmChangeEmailMutation } from "../../services/AssisApi";

interface Props {}

export default function Login({}: Props) {
  const [msg, setMsg] = useState("");
  const { code } = useParams();
  const navigate = useNavigate();
  const [SendChangeEmailConfirm, sendChangeEmailData] =
    useConfirmChangeEmailMutation();

  const enable = async () => {
    if (code) {
      await SendChangeEmailConfirm({ token: code });
      setMsg(sendChangeEmailData.data);
    } else {
      setMsg("Link invalido, solicite um novo link ao logar!");
    }
    setTimeout(() => navigate("/login"), 4000);
  };

  useEffect(() => {
    enable();
  }, []);

  return (
    <div className="login-screen">
      <div className="header">
        <img src={Logo} alt="Logo Assis" />
        <Divider className="divider" />
      </div>
      <div className="main">
        <Paper className="content" elevation={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={"h5"} align="center">
                Bem vindo ao Assis!
              </Typography>
              <Typography>{msg}</Typography>
              <Typography>
                Em breve iremos lhe redirecionar para o login!
              </Typography>
            </Grid>
            {/* <Grid item xs={12} className="keep-connected-wrapper">
              <Button variant="text">Esqueceu sua senha?</Button>
            </Grid>
            <Grid item xs={12} className="keep-connected-wrapper">
              <Typography variant="body2" align="center">
                Ao continuar, estou de acordo com os{" "}
                <a href="#">Termos de Uso e Política de Privacidade</a> do
                Assis.
              </Typography>
            </Grid> */}
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
