import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import "./styles.sass";
import Logo from "../../global/images/logo.svg";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLocalStorage } from "../../hooks/LocalStorage";
import { useSendAcceptTermMutation } from "../../services/AssisApi";
import { useSnackbar } from "notistack";

interface Props {}

export default function AcceptTerm({}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrollEnd, setScrollEnd] = useState(false);
  const [token, setToken, rmvToken] = useLocalStorage("token");
  const [acceptTerm, { isSuccess }] = useSendAcceptTermMutation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const terms = `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus finibus sem sed augue vestibulum imperdiet. Ut nec dui nibh. Vestibulum dignissim tortor a lectus finibus convallis. In hac habitasse platea dictumst. Sed volutpat tellus quis neque efficitur, in accumsan massa viverra. Ut efficitur id est eget lacinia. Cras eget odio ex. Curabitur vel metus in libero ultricies semper et et mi. Nulla id enim quam. Morbi ac massa massa. Nulla pretium nulla non semper maximus. In laoreet ligula ut massa lobortis ornare. Pellentesque accumsan congue arcu eget molestie. Aliquam malesuada tellus convallis, dictum tortor a, imperdiet ex. Donec sed est non nulla consectetur vehicula.

Proin tempus libero nec augue molestie tincidunt. Integer fringilla a nisi non tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consectetur nisi eu tellus pellentesque maximus. Curabitur semper dui eu diam rhoncus, sed imperdiet lorem tempor. Integer sem sapien, ornare eu faucibus sit amet, convallis sed justo. Proin interdum in tortor hendrerit pretium. Ut mi dui, consectetur in justo quis, fringilla fringilla justo.

Fusce a enim eget eros convallis tristique. Vivamus at tellus commodo, dignissim risus ac, blandit lacus. Aenean metus quam, iaculis et quam vitae, accumsan iaculis quam. Donec luctus elementum quam sed semper. Sed laoreet gravida ligula non interdum. Integer ultricies placerat tellus nec posuere. Fusce pulvinar iaculis lorem, eget tincidunt augue sagittis nec. Aenean placerat purus arcu, eu aliquam leo tempor ac. Ut vitae dignissim quam, nec feugiat dui. In libero est, consequat ornare sodales eu, efficitur vel ipsum. Aliquam et orci tortor.

Nulla blandit diam eu risus bibendum, in ornare ligula tempor. Integer velit ligula, volutpat sit amet velit sed, finibus lacinia velit. Nam eget tincidunt est. Duis sed venenatis metus, sit amet consectetur nisi. Phasellus id consequat ex, ut consequat dolor. Sed auctor nibh a nisi tincidunt lacinia. Vivamus bibendum purus nisl, pharetra consectetur augue vestibulum blandit. Sed lacinia sodales urna in accumsan.

Proin maximus dictum sollicitudin. Integer feugiat ut leo non laoreet. Aliquam mauris leo, blandit pharetra vestibulum sit amet, dictum sodales nisl. Aenean faucibus malesuada leo, ac molestie elit finibus eget. Proin maximus commodo magna nec consequat. Donec risus augue, tempor a erat quis, dictum mollis est. Nam id nulla turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis finibus in nisl non fringilla. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut ornare diam mollis odio rutrum, non laoreet augue consectetur. Pellentesque rutrum, risus vitae ornare scelerisque, dui tellus pharetra ex, eget sollicitudin enim odio elementum odio. Ut et leo ac purus finibus sodales vel vitae ipsum. Donec facilisis consectetur ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam laoreet sapien vitae ligula ultrices, nec tincidunt nulla maximus.

Phasellus commodo aliquam justo aliquam iaculis. Aenean nec tempus ipsum, non aliquam turpis. Duis ac nunc aliquam, egestas dolor a, dapibus risus. Nulla at consequat mi, nec consequat turpis. Nullam accumsan volutpat purus pulvinar gravida. Proin efficitur consequat orci, in euismod velit condimentum vitae. Maecenas sed leo velit.

Donec nisl odio, dapibus quis molestie nec, bibendum condimentum ipsum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut vitae arcu at lorem tempor tristique ut sit amet leo. Aenean molestie aliquam arcu dapibus interdum. Vivamus aliquet neque eros, at fermentum quam condimentum a. Curabitur tempor nisi quis ante ultricies, quis molestie metus eleifend. Mauris nec dui elit. Quisque hendrerit tellus in tincidunt interdum. Donec pellentesque nunc quis hendrerit maximus. Morbi aliquam orci diam, quis tristique velit pretium a. Integer non nunc finibus, vestibulum dui nec, ullamcorper metus.

Nunc sed massa consectetur, tincidunt mauris volutpat, condimentum mi. Suspendisse mauris nunc, euismod at nisi ac, efficitur pellentesque nibh. Nunc lacinia malesuada ultrices. Phasellus consectetur lobortis risus vel vestibulum. Curabitur diam odio, volutpat id mi a, rutrum sagittis felis. Morbi vel sodales lectus. Pellentesque fermentum neque vehicula, dictum massa eu, auctor sem.

Cras elementum tempus leo, sed molestie neque pulvinar cursus. Nunc vulputate bibendum maximus. Aenean eleifend sem a ante tempus tristique vel id felis. Cras pellentesque velit faucibus, lobortis libero et, elementum arcu. Sed tincidunt magna sit amet erat mollis, in congue metus molestie. Pellentesque bibendum vel nisi eu dictum. Mauris vehicula finibus bibendum. Sed congue dui sem, at tincidunt nisl pharetra nec. Nam porta lobortis dapibus. Phasellus mollis lectus massa, at porta tellus convallis non. Maecenas eu erat nisl. Proin eleifend nibh sit amet urna mattis feugiat. Cras ac sollicitudin nibh, in porta dui. Phasellus sollicitudin sapien sed quam euismod, ac condimentum dui accumsan.

Vestibulum eu tincidunt dui. Aliquam convallis aliquet nulla. Maecenas pharetra auctor velit. Maecenas convallis blandit ex, in aliquet nulla faucibus id. Aenean posuere nunc vel elit posuere rutrum. Sed nisi justo, vehicula sed rutrum in, ultricies nec eros. Duis ante nulla, dictum vel iaculis quis, feugiat id erat. Donec facilisis id lorem ac tempor. Nullam ultricies libero eros, eget rutrum libero sollicitudin vitae. Pellentesque vel sem ut tortor aliquam luctus.

Phasellus dignissim venenatis nunc id volutpat. Praesent convallis eget felis non consectetur. Praesent convallis orci sit amet pellentesque pellentesque. Sed aliquet nec lacus hendrerit consequat. Integer sit amet rhoncus orci. Suspendisse fermentum ante dui, vitae placerat nulla mollis dapibus. Nulla luctus rutrum mi, non congue massa auctor et.

Maecenas dapibus ante vulputate, maximus risus sit amet, condimentum ligula. Mauris dignissim, risus et facilisis consequat, velit nulla rutrum felis, sed condimentum ex ipsum nec dui. Duis efficitur hendrerit turpis, sit amet sollicitudin risus dignissim luctus. Duis lacinia, leo sed venenatis varius, elit ex tincidunt libero, ut euismod erat nunc vitae enim. Quisque interdum enim at arcu accumsan congue. Mauris in tincidunt risus. Sed posuere risus quis diam porttitor euismod. Aliquam congue consectetur sem, vel congue metus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Curabitur vel aliquam purus, at volutpat purus. Nullam tincidunt congue orci eu pulvinar. Pellentesque semper congue metus ac mattis. Maecenas egestas, arcu id lobortis iaculis, velit nisl hendrerit mauris, fringilla gravida mauris ipsum tristique quam.

Curabitur eu nisi auctor, eleifend ex non, ornare mi. Integer non interdum odio, dignissim mattis ipsum. Vivamus consequat lobortis molestie. Morbi ac tortor magna. Nulla sollicitudin nibh luctus semper maximus. Fusce iaculis, est ac consectetur posuere, eros diam molestie ex, quis luctus tortor magna ut purus. Interdum et malesuada fames ac ante ipsum primis in faucibus.

Maecenas eu enim mattis odio pellentesque varius convallis in metus. Pellentesque id porta est. Maecenas scelerisque quam eu tortor tempus aliquet. Vivamus ultricies quam a felis elementum, eu interdum enim congue. Donec dolor leo, condimentum eget elit sit amet, faucibus mattis ex. Praesent ut dui quis dolor hendrerit iaculis. Duis nec enim eu est porta feugiat. Suspendisse euismod porttitor venenatis. Sed posuere erat eu libero ultricies fermentum. Nunc non augue gravida, faucibus est in, blandit orci. Ut vehicula convallis pellentesque. In sapien felis, condimentum at ex at, tincidunt mollis quam. Integer mattis ipsum a leo efficitur malesuada. Sed bibendum tortor lacus, vel iaculis mi laoreet non. Morbi sed nulla justo. Nullam accumsan quam eu libero ultrices, quis facilisis nisi aliquet.

Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean placerat placerat finibus. Pellentesque luctus diam auctor, tincidunt diam eget, mattis felis. Vestibulum lobortis lorem purus, sit amet egestas felis blandit sit amet. Vivamus at tempus nisl, vitae molestie justo. Pellentesque pharetra orci nec odio consectetur mollis. Aenean quis magna vulputate, porta libero at, aliquam massa. Vestibulum venenatis mauris elit, fermentum lacinia nisi dictum at. Nulla ut tempus urna, id varius metus.

Mauris sollicitudin, arcu a rutrum blandit, ante metus rhoncus ex, sit amet luctus orci neque sit amet leo. Nulla facilisi. Duis maximus eu leo eget lobortis. Donec faucibus elementum massa id sollicitudin. Nam lacinia vel lectus ut luctus. In eleifend turpis sit amet risus interdum varius. Sed quis tempor nunc, eget placerat ipsum.

Sed et euismod arcu. Ut dictum pulvinar tempus. Sed vitae odio quis lorem luctus iaculis. Suspendisse ut orci malesuada, rutrum enim a, elementum urna. Phasellus molestie nec tellus at placerat. Curabitur non viverra arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ut consequat sem. Sed in mattis urna. Nullam ullamcorper mattis imperdiet. Ut at ex magna. Cras venenatis nec massa sit amet commodo. Morbi vitae dui porttitor tellus semper maximus eget non urna.

Nam tempus enim eu risus porttitor, eget imperdiet lacus volutpat. Suspendisse vitae pharetra risus, semper gravida enim. Donec sit amet condimentum nulla, vel fermentum odio. Nullam et blandit quam, sed condimentum ante. Ut tempus magna felis, at cursus arcu tristique id. Integer facilisis in turpis et tristique. Fusce feugiat purus id erat elementum, quis sodales lorem gravida. Aliquam tincidunt nibh orci, at bibendum tellus sollicitudin a. Nulla venenatis faucibus luctus. Etiam convallis nisl eget pellentesque accumsan. Etiam placerat vitae mauris mollis sodales. Vestibulum id nisi in erat aliquet tempor non ut ligula. Fusce et ligula erat. Sed eget dapibus eros. Pellentesque sed magna nulla.

Sed ac finibus urna. Duis gravida leo leo, ac aliquet libero ultrices eget. Proin in rutrum lacus. Nam pretium massa id elit auctor varius. In facilisis orci vitae ligula dapibus, auctor commodo augue convallis. Cras vehicula odio eu felis sagittis, a tempus eros consequat. Proin dignissim laoreet massa a tincidunt. Praesent erat leo, facilisis nec enim eget, pulvinar ultricies metus. In imperdiet odio tellus, tempor hendrerit ipsum molestie rutrum. Sed a justo laoreet justo dignissim efficitur. Nunc et eros eget lorem pulvinar finibus. Duis id dolor nisi. Nulla at pellentesque quam. Vestibulum vitae urna aliquet, luctus lorem vitae, semper nulla. Fusce malesuada justo in lectus rutrum, ut dignissim dui consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit.

Cras in cursus nisi. Donec ut lectus nisi. Integer fermentum, sapien in consectetur accumsan, magna velit aliquet erat, eu feugiat lectus erat quis nunc. Ut non felis at libero tincidunt accumsan. Ut nisl metus, ornare in facilisis eget, iaculis vitae ex. Ut eget augue mattis sapien pharetra consectetur vitae et enim. Sed finibus dui id urna facilisis dignissim. Nulla facilisi.
  `;

  // useEffect(() => {
  //   if (userInfo && !userInfo?.isFirstAccess) navigate("/i");
  // }, [userInfo]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (
      event.currentTarget.offsetHeight + event.currentTarget.scrollTop >=
      event.currentTarget.scrollHeight
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const handleAccept = async () => {
    enqueueSnackbar("Enviando aceite!", { key: "send", variant: "info" });
    await acceptTerm();
    closeSnackbar("send");
    enqueueSnackbar("Enviando aceite!", { key: "send", variant: "success" });
    navigate("/", { replace: true });
    // navigate(0);
  };

  const handleCancel = () => {
    rmvToken();
    window.location.reload();
  };

  return (
    <Grid container spacing={1} id="accept-term-wrapper">
      <Grid item xs={12} id={"logo"} mt={2}>
        <img src={Logo} alt="Logo Assis" />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Divider className="divider" />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={8} mt={3}>
        <Paper>
          <Grid container p={4}>
            <Grid item xs={12}>
              <Typography variant={"h5"}>
                Termos de uso e políticas de privacidade
              </Typography>
            </Grid>
            <Grid item xs={12} pt={1} id={"scroll-paper"}>
              <Paper onScroll={handleScroll}>
                <Typography p={4}>{terms}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} mt={2} justifyItems={"right"} id={"actions"}>
              <Button
                variant={"contained"}
                disabled={!scrollEnd}
                onClick={handleAccept}
              >
                Li e aceito os termos de uso
              </Button>
              <Button onClick={handleCancel}>Cancelar</Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
}
