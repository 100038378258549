import {
  Alert,
  Button,
  ButtonProps,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import "./styles.sass";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Visibility as ShowPasswordIcon,
  VisibilityOff as HidePasswordIcon,
  Mail as MailIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { ResponseError } from "../../types";
import { useState } from "react";
import { useSignupMutation } from "../../services/AssisApi";
import { RTKFetchError } from "../../services/Helpers";

interface Props extends ButtonProps {}

export default function SignupForm({}: Props) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [signup, { isLoading, isError, data, error, reset }] =
    useSignupMutation();

  const formValidationSchema = yup.object({
    name: yup.string().required("Informe seu nome"),
    email: yup
      .string()
      .required("Digite seu email")
      .email("Formato de email inválido"),
    password: yup
      .string()
      .required("Digite sua senha")
      .min(4, "Utilize uma senha com ${min} caracteres ou mais"),
    passwordConfirm: yup
      .string()
      .required("Confirme a senha")
      .oneOf([yup.ref("password"), null], "As senhas não conferem"),
  });

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: formValidationSchema,
    onSubmit: async (values) => {
      try {
        await signup({
          name: values.name,
          email: values.email,
          password: values.password,
        });
        form.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form noValidate autoComplete="off" onSubmit={form.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* {error.message && (
            <Alert
              severity="error"
              onClose={() => setError({} as ResponseError)}
            >
              {error.message}
            </Alert>
          )} */}
          {data && (
            <Alert
              severity="success"
              onClose={() => {
                form.resetForm();
                reset();
              }}
            >
              {data.message}
            </Alert>
          )}
          {isError && (
            <Alert
              severity="error"
              onClose={() => {
                reset();
              }}
            >
              {RTKFetchError(error)?.message}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="name"
            label="Nome"
            placeholder="Seu nome"
            size="small"
            fullWidth
            margin="dense"
            disabled={isLoading}
            InputProps={{
              startAdornment: <MailIcon fontSize="small" />,
            }}
            value={form.values.name}
            onChange={form.handleChange}
            error={form.touched.name && Boolean(form.errors.name)}
            helperText={form.touched.name && form.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="email"
            label="Email"
            placeholder="seu@email.com"
            size="small"
            fullWidth
            margin="dense"
            disabled={isLoading}
            InputProps={{
              startAdornment: <MailIcon fontSize="small" />,
            }}
            value={form.values.email}
            onChange={form.handleChange}
            error={form.touched.email && Boolean(form.errors.email)}
            helperText={form.touched.email && form.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="password"
            label="Senha"
            placeholder="*****"
            size="small"
            fullWidth
            margin="dense"
            autoComplete="on"
            disabled={isLoading}
            type={showPassword ? "text" : "password"}
            InputProps={{
              startAdornment: <LockIcon fontSize="small" />,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    disabled={isLoading}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={form.values.password}
            onChange={form.handleChange}
            error={form.touched.password && Boolean(form.errors.password)}
            helperText={form.touched.password && form.errors.password}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="passwordConfirm"
            label="Confirme a senha"
            placeholder="*****"
            size="small"
            fullWidth
            margin="dense"
            autoComplete="on"
            disabled={isLoading}
            type={showPasswordConfirm ? "text" : "password"}
            InputProps={{
              startAdornment: <LockIcon fontSize="small" />,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    disabled={isLoading}
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                  >
                    {showPasswordConfirm ? (
                      <HidePasswordIcon />
                    ) : (
                      <ShowPasswordIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={form.values.passwordConfirm}
            onChange={form.handleChange}
            error={
              form.touched.passwordConfirm &&
              Boolean(form.errors.passwordConfirm)
            }
            helperText={
              form.touched.passwordConfirm && form.errors.passwordConfirm
            }
          />
        </Grid>
        <Grid item xs={12} className="keep-connected-wrapper">
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={isLoading}
          >
            Criar conta
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                }}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
