import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import { LoginGoogleParams } from "../types";

const firebaseConfig = {
  apiKey: "AIzaSyAWUE88IcqxYCgUigCa4o5HchInfCPRBaA",
  authDomain: "assis-console-dev.firebaseapp.com",
  projectId: "assis-console-dev",
  storageBucket: "assis-console-dev.appspot.com",
  messagingSenderId: "786495600285",
  appId: `1:786495600285:web:2387c69cec36736b4a4674`,
};

// var serviceAccount = JSON.parse(process.env.REACT_APP_FIREBASE as string);
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const auth = getAuth();
const provider = new GoogleAuthProvider();

// provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

export const googleLogin = async () => {
  return new Promise<LoginGoogleParams>((resolve, reject) => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = (await auth.currentUser?.getIdToken()) as string;
        resolve({ googleToken: token });
      })
      .catch((error) => {
        reject("Falha ao realizar login com google");
      });
  });
};

const storage = getStorage();

export const downloadFile = (file: string) => {
  getDownloadURL(
    ref(storage, `${process.env.REACT_APP_FIREBASE_FOLDER}/${file}`)
  )
    .then((url) => {
      // // `url` is the download URL for 'images/stars.jpg'

      // // This can be downloaded directly:
      // const xhr = new XMLHttpRequest();
      // xhr.responseType = 'blob';
      // xhr.onload = (event) => {
      //   const blob = xhr.response;
      // };
      // xhr.open('GET', url);
      // xhr.send();

      window.open(url, "blank");
    })
    .catch((error) => {
      // Handle any errors
    });
};

// export default {
//   googleLogin,
// };
