import "./styles.sass";
import MainFooter from "../../components/MainFooter";
import MainSidebar from "../../components/MainSidebar";
import MainHeader from "../../components/MainHeader";
import { Outlet } from "react-router-dom";

// interface Props extends ButtonProps {}

export default function MainTemplate() {
  return (
    <div className="main-template">
      <div className="content">
        <MainSidebar />
        <div className="main">
          <MainHeader />
          <div className="screen-content">
            <Outlet />
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
}
