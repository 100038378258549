import {
  Alert,
  alertClasses,
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { AddCircleOutline as AddInstanceIcon } from "@mui/icons-material";

import "./styles.sass";
import InstanceButton from "../../components/InstanceButton";
import Screen from "../../components/Screen";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteInstanceMutation,
  useEditInstanceMutation,
  useGetIntancesQuery,
} from "../../services/AssisApi";
import { Box } from "@mui/system";
import * as yup from "yup";
import { useFormik } from "formik";
import { RTKFetchError } from "../../services/Helpers";
import { useEffect } from "react";
import { Instance, ReactRoutesState } from "../../types";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

interface Props {}

export default function InstanceSettings({}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedInstance: Instance = useSelector(
    (state: RootState) => state.app.selectedInstance
  );
  const { instanceId } = useParams();
  // const {
  //   data: instances,
  //   // error: getInstancesError,
  //   // isLoading: getInstancesIsLoading,
  //   // refetch,
  // } = useGetIntancesQuery(undefined, { refetchOnMountOrArgChange: true });

  const [
    editInstance,
    {
      isLoading: editInstanceIsLoading,
      isError: editInstanceIsError,
      data: editInstanceData,
      error: editInstanceError,
      reset: editInstanceReset,
    },
  ] = useEditInstanceMutation();

  const validationSchema = yup.object({
    description: yup
      .string()
      .required("Informe a descrição do ambiente")
      .min(3, "O nome do ambiente deve conter pelo menos ${min} caracteres")
      .max(25, "O nome do ambiente deve conter no máximo ${max} caracteres"),
  });

  const form = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await editInstance({
          id: instanceId || "",
          description: values.description,
        });
        // form.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    form.setFieldValue("description", selectedInstance.description);
  }, [selectedInstance.description]);

  return (
    <div className="instance-settings-screen">
      <Screen>
        <Paper elevation={2} className="instance-info">
          <Typography variant="h6">Dados do ambiente</Typography>

          {editInstanceData && (
            <Alert
              severity="success"
              onClose={() => {
                // form.resetForm();
                editInstanceReset();
              }}
            >
              {editInstanceData.message}
            </Alert>
          )}
          {editInstanceIsError && (
            <Alert
              severity="error"
              onClose={() => {
                editInstanceReset();
              }}
            >
              {RTKFetchError(editInstanceError)?.message}
            </Alert>
          )}

          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={form.handleSubmit}
          >
            <Grid container spacing={1}>
              <Grid className="row" item xs={12}>
                <TextField
                  className="input"
                  name="description"
                  label="Nome do ambiente"
                  placeholder="Informe seu nome"
                  size="small"
                  margin="normal"
                  fullWidth
                  inputProps={{
                    minLength: 3,
                    maxLength: 25,
                  }}
                  disabled={editInstanceIsLoading}
                  value={form.values.description}
                  onChange={form.handleChange}
                  sx={{
                    width: 400,
                  }}
                  error={
                    form.touched.description && Boolean(form.errors.description)
                  }
                  helperText={
                    form.touched.description && form.errors.description
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={editInstanceIsLoading}
                >
                  Salvar
                  {editInstanceIsLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                      }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Paper elevation={2} className="instance-delete">
          <Typography variant="h6">Apagar ambiente</Typography>
          <Alert color="warning" className="instance-delete-warning">
            Essa ação irá apagar definitivamente os dados do ambiente
          </Alert>
          <Button
            type="submit"
            variant="contained"
            color="error"
            onClick={() => {
              navigate(`delete-confirm`, {
                state: { backgroundLocation: location },
              });
            }}
          >
            Apagar
            {/* {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                  }}
                />
              )} */}
          </Button>
        </Paper>
      </Screen>
    </div>
  );
}
