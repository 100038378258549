import {
  Button,
  ButtonBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import StarterIcon from "@mui/icons-material/Flag";
import PremiumIcon from "@mui/icons-material/WorkspacePremium";
import SupportIcon from "@mui/icons-material/SupportAgent";
import FeatureOffIcon from "@mui/icons-material/CloseOutlined";
import FeatureOnIcon from "@mui/icons-material/CheckCircle";

import "./styles.sass";
import Screen from "../../components/Screen";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { UserPlan } from "../../types";

interface Props {}

export default function Plans({}: Props) {
  const navigate = useNavigate();
  const userInfo = useSelector(
    (state: RootState) => state.auth.authenticatedUser
  );

  return (
    <div className="plans-screen">
      <Screen>
        <Typography variant="h5">Mude seu plano!</Typography>
        <Typography variant="subtitle1">
          Selecione uma opção e faça upgrade para atender às suas necessidades.
        </Typography>

        <div className="plans-list">
          {userInfo.plan === UserPlan.STARTER && (
            <Paper elevation={3} className="plan royal">
              <Typography variant="subtitle2" className="name">
                <StarterIcon />
                Gratuito
              </Typography>
              <Typography variant="subtitle2" className="subtitle">
                Inclui as funcionalidades básicas do Assis.
              </Typography>
              <div className="price">
                <Typography variant="h4" className="value">
                  R$ 0
                </Typography>
                <Typography variant="body1" className="description">
                  Por usuário /Mês
                </Typography>
              </div>
              <Button variant="contained" disabled className="action-button">
                Plano atual
              </Button>
              <Button variant="contained" className="action-button">
                Voltar para o gratuito
              </Button>
              <List dense className="features">
                <ListItem>
                  <ListItemIcon>
                    <FeatureOnIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Grupos de risco" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Análises do risco de evasão ilimitadas" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Métricas comportamentais" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Indicadores do potencial risco de evasão" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Consultoria" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Download de relatórios completos" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Filtro de tutores, cursos, turmas e alunos para análise personalizada" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Comparação dos dados entre análises e amostra de progresso" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Controle de cadastro e acesso de usuários" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Alertas e notificações" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Chatbot interativo" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Suporte à integração de dados" />
                </ListItem>
                <ListItem disabled>
                  <ListItemIcon>
                    <FeatureOffIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Perfil do aluno concludente/evasor" />
                </ListItem>
              </List>
            </Paper>
          )}

          <Paper elevation={3} className="plan yellow">
            <Typography variant="subtitle2" className="name">
              <PremiumIcon />
              Premium
            </Typography>
            <Typography variant="subtitle2" className="subtitle">
              Inclui todas as funcionalidades do Assis.
            </Typography>
            <div className="price">
              <Typography variant="h4" className="value">
                R$ 99
              </Typography>
              <Typography variant="body1" className="description">
                Por usuário /Mês
              </Typography>
            </div>
            {userInfo.plan === "starter" && (
              <Button
                variant="contained"
                className="action-button"
                onClick={() => {
                  navigate("/plans/payment");
                }}
              >
                Mudar para o premium
              </Button>
            )}
            {userInfo.plan === "premium" && (
              <Button variant="contained" disabled className="action-button">
                Plano atual
              </Button>
            )}
            <List dense className="features">
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Grupos de risco" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Análises do risco de evasão ilimitadas" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Métricas comportamentais" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Indicadores do potencial risco de evasão" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Consultoria" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Download de relatórios completos" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Filtro de tutores, cursos, turmas e alunos para análise personalizada" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Comparação dos dados entre análises e amostra de progresso" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Controle de cadastro e acesso de usuários" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Alertas e notificações" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Chatbot interativo" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Suporte à integração de dados" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Perfil do aluno concludente/evasor" />
              </ListItem>
            </List>
          </Paper>

          <Paper elevation={3} className="plan purple">
            <Typography variant="subtitle2" className="name">
              <SupportIcon />
              Suporte total
            </Typography>
            <Typography variant="subtitle2" className="subtitle">
              Inclui todas as funcionalidades do Assis + suporte dos
              especialistas.
            </Typography>
            <div className="price">
              <Typography variant="h4" className="value">
                Entre em contato
              </Typography>
              {/* <Typography variant="body1" className="description">
                Por usuário /Mês
              </Typography> */}
            </div>
            <Button
              variant="contained"
              className="action-button"
              onClick={() => {
                navigate("/plans/payment");
              }}
            >
              Mudar para Suporte total
            </Button>
            <List dense className="features">
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Grupos de risco" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Análises do risco de evasão ilimitadas" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Métricas comportamentais" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Indicadores do potencial risco de evasão" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Consultoria" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Download de relatórios completos" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Filtro de tutores, cursos, turmas e alunos para análise personalizada" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Comparação dos dados entre análises e amostra de progresso" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Controle de cadastro e acesso de usuários" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Alertas e notificações" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Chatbot interativo" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Suporte à integração de dados" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Perfil do aluno concludente/evasor" />
              </ListItem>
            </List>
          </Paper>
        </div>
      </Screen>
    </div>
  );
}
