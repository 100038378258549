import { AlertColor } from "@mui/material";

export type ResponseError = {
  statusCode: number;
  message: string;
  error: string;
};

export type SimpleMessageResponse = {
  message: string;
};

export type LoginParams = {
  email: string;
  password: string;
};

export type LoginGoogleParams = {
  googleToken: string;
};

export type LoginResponse = {
  token: string;
};

export type Token = {
  token: string;
};

export type SignupParams = {
  name: string;
  password: string;
  // passwordConfirm: string;
  email: string;
};

export enum UserPlan {
  STARTER = "starter",
  PREMIUM = "premium",
}

export type GetMeResponse = {
  id: string;
  name: string;
  email: string;
  picture?: string;
  whatsapp?: string;
  plan: UserPlan;
  isFirstAccess: boolean;
  isNewPremium: boolean;
  acceptTerm: boolean;
  preApprovalCode: string;
};

export type CreateInstanceParams = {
  description: string;
};

export type EditInstanceParams = {
  id: string;
  description: string;
};

export type CreateInstanceResponse = {
  message: string;
  instance: Instance;
};

export type Instance = {
  _id: string;
  description: string;
  token: string;
};

export enum BatchProccessStatus {
  proccesing = "Processando",
  success = "Sucesso",
  failure = "Falha",
}

export type Batch = {
  _id: string;
  startAt: Date;
  endAt: Date;
  origin: string;
  headers: string;
  file: string;
  processStatus: BatchProccessStatus;
  response: string;
  courseCount: number;
  tutorCount: number;
  studentCount: number;
};

export type ReactRoutesState = {
  pageTitle: string;
};

export type NewBatchParams = {
  instanceId: string;
  formData: FormData;
};

export type DeleteBatchParams = {
  instanceId: string;
  batchId: string;
};

export type UploadBatch = {
  courses: UploadCourse[];
  tutors: UploadTutor[];
  students: UploadStudent[];
  errors: UploadErro[];
};

export type UploadErro = {
  type: AlertColor;
  message: string;
};

export type UploadCourse = {
  id: number;
  name: string;
  tags: string[];
  tutors: number[];
  students: number[];
};

export type UploadTutor = {
  id: number;
  name: string;
  tags: string[];
};

export type UploadStudent = {
  id: number;
  name: string;
  tags: string[];
  course: number;
  tutors: number[];
  accessTime: number;
  attempts: number;
  timeLeft: number;
  averageGrade: number;
  finished: boolean;
  evasor: boolean;
};

export type UpdateUser = {
  email?: string;
  name?: string;
  whatsapp?: string;
  password?: string;
};

export type CreateInstance = {
  description: string;
};

export type GetEvasionParams = {
  type?: string;
  courseIds?: number[];
  batchId: string;
};

export type Analisys = {
  color: string;
  description: string;
  percent: number;
  qty: number;
  title: string;
  tooltip: string;
  studentIds: number[];
};

export type Evasion = {
  studentCount: number;
  courseCount: number;
  tutorCount: number;
  analisys: Analisys[];
  evasorConcludents: [];
  courseRanking: Ranking[];
  studentRanking: Ranking[];
  tutorRanking: Ranking[];
  tutorIds: number[];
};

export type Course = {
  _id: string;
  courseId: number;
  name: string;
};
export type Tutor = {
  _id: string;
  tutorId: number;
  name: string;
};
export type Class = {
  _id: string;
  classId: number;
  name: string;
  startDate: Date;
  endDate: Date;
};

export type Ranking = {
  name: string;
  group: string;
  color: string;
};

export type GetCoursesParams = {
  id?: number;
  batch: string;
};

type DateRange = { $lte: string | undefined; $gte: string | null | undefined };

export type GetRecommendersParams = {
  type?: string;
  "tutor.name"?: string;
  "class.name"?: string;
  "course.name"?: string;
  "class.classid"?: number;
  tag?: string;
  instance?: string;
  date?: DateRange;
  [key: string]: string | number | DateRange | undefined;
};

export type PasswordRecoverySolicitateParams = {
  email: string;
};

export type ChangePasswordByRecoverySolicitateParams = {
  code: string;
  password: string;
  passwordConfirm: string;
};

export type Recommender = {
  // problem: string;
  // recommendation: string;
  // type: string;
  // studentCount: number;
  // name: string;
  // percent: number;
  // studentIds: number[];
  // list?: string[];
  // tags: string[];

  name: string;
  problem: string;
  recommendation: string;
  studentIds: number[];
  list: string[];
  courseId: number;
  course: Course;
  class: Class;
  tutor: Tutor;
  tutorIds: number[];
  batch: string;
  instance: string;
  percent: number;
  type: string;
  date: Date;
  favorite: boolean;
  done: boolean;
  tags: string[];
};

export type GetIndicatorsParams = {
  batchId: string;
  courseIds?: number[];
};

export type GetRecommenderHistoryQS = {
  instance: string;
  course?: number;
};

export type Indicator = {
  access: string;
  attempts: string;
  description: string;
  grade: string;
  id: string;
  studentCount: number;
  name: string;
  percent: number;
  precedency: string;
  tooltip: string;
  studentIds: number[];
};

export type Classification = {
  avg: number;
  color: string;
  percent: number;
  studentIds: number[];
  title: string;
  total: number;
};

export type GetMetricsParams = {
  batchId: string;
  courseIds?: number[];
};

export type Metric = {
  title: string;
  subtile: string;
  tooltip: string;
  icon: string;
  classifictations: Classification[];
};

export type PaymentMethod = {
  cardNumber: string;
  validDate: string;
  ccv: string;
  cardName: string;
  id?: string;
};

export type AcceptPlan = {
  name: string;
  email: string;
  ip: string;
  phone: string;
  street: string;
  number: string;
  complement: string;
  district: string; // bairro
  city: string;
  state: string; // Sigla (UF)
  postalCode: string;
  docValue: string;
  cardHolder: string;
  birthDate: string;
  cardNumber: string;
  cardCvv: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
};

export type GetStudentsListByIdsRequest = {
  studentId: number[];
  batch: string;
  courseId?: number;
};

export type GetTutorsListByIdsRequest = {
  tutorIds: number[];
  batch: string;
  courseId?: number;
};

export type GetStudentsListByIdsResponse = {
  name: string;
};
