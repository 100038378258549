import { Button, ButtonProps } from "@mui/material";

import "./styles.sass";
import GoogleLogo from "./images/google-logo.svg";
import { googleLogin } from "../../services/Firebase";
import { useLocalStorage } from "../../hooks/LocalStorage";
import { useLoginGoogleMutation } from "../../services/AssisApi";
import { useEffect } from "react";

interface Props extends ButtonProps {}

export default function GoogleLoginButton({ style, disabled }: Props) {
  const [loginGoogle, { isLoading, isError, data, error }] =
    useLoginGoogleMutation();
  const [token, setToken] = useLocalStorage("token");

  const googleLoginHandler = async () => {
    const googleUserData = await googleLogin();
    await loginGoogle(googleUserData);
  };

  useEffect(() => {
    if (data?.token) {
      setToken(data.token);
      window.location.reload();
    }
  }, [data]);

  useEffect(() => {
    if (error)
      console.log("🚀 ~ file: index.tsx ~ line 31 ~ useEffect ~ error", error);
  }, [error]);

  return (
    <Button
      fullWidth
      variant="outlined"
      style={style}
      className="google-login-button"
      disabled={disabled}
      onClick={() => {
        googleLoginHandler();
      }}
    >
      <img src={GoogleLogo} alt="Google logo" className="google-logo" />
      Continuar com Google
    </Button>
  );
}
