import { configureStore } from "@reduxjs/toolkit";
import { AssisApi } from "../services/AssisApi";
import appSlice from "./slices/app";
import authSlice from "./slices/auth";
import recommendationSlice from "./slices/recommendation";

const store = configureStore({
  reducer: {
    [AssisApi.reducerPath]: AssisApi.reducer,
    auth: authSlice,
    app: appSlice,
    recommendations: recommendationSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([AssisApi.middleware]);
  },
});
export type RootState = ReturnType<typeof store.getState>;

export default store;
