import "./styles.sass";
import {
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CourseIcon from "@mui/icons-material/MenuBook";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";

import { Course, Ranking } from "../../types";
import { Group, Groups } from "@mui/icons-material";

interface Props {
  data: Ranking[];
  type: "course" | "student" | "tutor";
}

const Component = ({ type, data }: Props) => {
  const getTitle = () => {
    switch (type) {
      case "course":
        return (
          <>
            <CourseIcon sx={{ marginRight: "5px" }} /> Cursos{" "}
          </>
        );
      case "student":
        return (
          <>
            <Group sx={{ marginRight: "5px" }} /> Alunos{" "}
          </>
        );
      case "tutor":
        return (
          <>
            <Group sx={{ marginRight: "5px" }} /> Tutores{" "}
          </>
        );
    }
  };

  return (
    <Grid container className="analysis-result-ranking">
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "50%" }}>
                  <Typography
                    variant={"h6"}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    {getTitle()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant={"h6"}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    <>
                      <Groups sx={{ marginRight: "5px" }} /> Grupo de risco
                    </>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    <Typography>{row.name}</Typography>
                  </TableCell>
                  <TableCell sx={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={"color-bar"}
                      style={{ background: row.color }}
                    ></div>
                    <Typography>{row.group}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Component;
