import {
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddCircleOutline as AddInstanceIcon,
  InfoOutlined,
  LocationOn,
} from "@mui/icons-material";
import "./styles.sass";
import Screen from "../../components/Screen";
import { useNavigate } from "react-router-dom";
import { MouseEvent, useEffect, useState } from "react";
import { Box } from "@mui/system";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  useCancelPlanMutation,
  useGetInvoicesQuery,
} from "../../services/AssisApi";
import moment from "moment";
import { useSelector } from "react-redux";
import { UserPlan } from "../../types";
import { RootState } from "../../store";
import { useSnackbar } from "notistack";

interface Props {}

export default function Invoices({}: Props) {
  const navigate = useNavigate();
  const userInfo = useSelector(
    (state: RootState) => state.auth.authenticatedUser
  );
  const { data, error, isLoading } = useGetInvoicesQuery();
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [popoverText, setPopoverText] = useState<String>("");
  const [cancelPlan, responseCancelPlan] = useCancelPlanMutation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (responseCancelPlan.isError) {
      closeSnackbar();
      enqueueSnackbar(
        "Ocorreu um erro ao cancelar sua assinatura, tente novamente!",
        { variant: "error" }
      );
    }
    if (responseCancelPlan.isSuccess) {
      closeSnackbar();
      enqueueSnackbar("Assinatura cancelada com sucesso", {
        variant: "success",
      });
      navigate("/");
    }
  }, [responseCancelPlan.isLoading]);

  const handlePopoverOpen = (
    event: MouseEvent<SVGSVGElement>,
    text: String
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverText(text);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const goUpgrade = () => {
    navigate("/plans");
  };

  const handleCancel = async () => {
    enqueueSnackbar("Estamos cancelando sua assinatura", { variant: "info" });
    await cancelPlan();
  };

  return (
    <Screen>
      {isLoading ? (
        <CircularProgress size={36} />
      ) : !userInfo.preApprovalCode ? (
        <Paper id={"starter-paper"}>
          <Grid container p={2} spacing={1}>
            <Grid item xs={12}>
              <Typography fontWeight={700}>
                Você está utilizando o plano gratuito do Assis, por isso ainda
                não possui informações de faturamento e pagamentos.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Torne seu plano para o Premium e tenha acesso a todos os
                recursos que o Assis oferece para mitigar a evasão.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant={"contained"} onClick={goUpgrade}>
                Atualizar Plano
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper>
          <Grid container p={4} spacing={1}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 500 }}>
                Este é o resumo do faturamento
              </Typography>
            </Grid>
            <Grid item xs={12} mb={4}>
              <Typography>
                Agora é mais fácil conferir todas as suas cobranças e pagamentos
                em um só lugar.
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  <Paper>
                    <Box p={2}>
                      <Typography sx={{ fontWeight: 500 }}>
                        Próximo pagamento automático
                      </Typography>
                      <Typography component={"span"} sx={{ fontWeight: 500 }}>
                        {data.payments[0]?.amount}
                      </Typography>{" "}
                      <Typography component={"span"} sx={{ float: "right" }}>
                        {moment(data.payments[0].schedulingDate).format("LL")}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                {/* <Grid item xs={6}>
                  <Paper>
                    <Box p={2}>
                      <Typography sx={{ fontWeight: 500 }}>
                        Último pagamento
                      </Typography>
                      <Typography component={"span"} sx={{ fontWeight: 500 }}>
                        {data.payments[1]?.amount || "NO DATA"}
                      </Typography>{" "}
                      <Typography component={"span"} sx={{ float: "right" }}>
                        {moment(data.payments[1]?.schedulingDate).format(
                          "LL"
                        ) || "NO DATA"}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid> */}
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Atividade anterior
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Grid container p={2}>
                      {data.payments.map((el: any) => (
                        <>
                          <Grid item xs={2}>
                            <Stack direction={"row"} spacing={1}>
                              <Typography
                                sx={{
                                  color: el.status.severity,
                                  fontWeight: 700,
                                }}
                              >
                                {el.status.text}
                              </Typography>
                              <InfoOutlined
                                onMouseEnter={(e) =>
                                  handlePopoverOpen(e, el.status.tooltip)
                                }
                                onMouseLeave={handlePopoverClose}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={6} textAlign={"center"}>
                            <Typography sx={{ fontWeight: 500 }}>
                              {moment(el.schedulingDate).format("MMMM YYYY")}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} textAlign={"end"}>
                            <Typography sx={{ fontWeight: 500 }}>
                              {el.amount}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider></Divider>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Perfil de pagamentos
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Grid container p={2} spacing={1}>
                      <Grid item xs={12}>
                        <Stack direction={"row"}>
                          <AccountCircleIcon />
                          <Typography sx={{ fontWeight: 500 }}>
                            Dados pessoais
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>{data.sender.name}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>{data.sender.email}</Typography>
                      </Grid>
                      {/* <Grid item xs={12}>
                      <Typography>CPF: xxxxxxxxxx</Typography>
                    </Grid> */}
                      <Grid item xs={12}>
                        <Typography>{`(${data.sender.phone.areaCode[0]}) ${data.sender.phone.number[0]}`}</Typography>
                      </Grid>
                      <Grid item xs={12} mt={3}>
                        <Stack direction={"row"}>
                          <LocationOn />
                          <Typography sx={{ fontWeight: 500 }}>
                            Endereço
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {`${data.sender.address.street[0]}, ${data.sender.address.number[0]} ${data.sender.address.complement[0]}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {data.sender.address.district[0]}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>{data.sender.address.city[0]}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>{data.sender.address.state[0]}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {data.sender.address.postalCode[0]}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {data.sender.address.country[0]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={2}>
              <Button
                color={"error"}
                variant={"contained"}
                onClick={handleCancel}
              >
                Cancelar assinatura
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button variant={"contained"} onClick={goUpgrade}>
                Alterar Plano
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{popoverText}</Typography>
      </Popover>
    </Screen>
  );
}
