import { createTheme } from "@mui/material/styles";
import { ptBR } from "@mui/x-data-grid";

const colors = {
  royal: "#3B8FE4",
  blue: "#3C3BE5",
  gray: "#999999",
  dark: "#333333",
  // error: "#FF0000",
};

const fonts = {
  // primary: "Roboto Slab",
  // secondary: "Roboto",
};

export const theme = createTheme(
  {
    typography: {
      // fontFamily: ["Roboto Slab", "serif", "Roboto", "sans-serif"].join(","),
      // fontWeightRegular: 600,
    },

    palette: {
      primary: {
        main: colors.royal,
      },
      // error: {
      // main: colors.error,
      // },
    },

    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            "&.Mui-selected": {
              color: colors.dark,
            },
          },
          textColorSecondary: {
            "&.Mui-selected": {
              color: colors.royal,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            // borderWidth: "2px",
            // borderColor: colors.dark,
            // transition: ".5s",
            // "&:hover .MuiOutlinedInput-notchedOutline": {
            //   boxShadow: `0 2px 2px 0 ${colors.gray}`,
            // },
          },
          // notchedOutline: {
          //   borderWidth: "2px",
          //   borderColor: colors.dark,
          // },
          input: {
            paddingLeft: 10,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            borderRadius: 100,
          },
        },
      },

      // MuiInputLabel: {
      //   styleOverrides: {
      //     root: {
      //       color: colors.dark,
      //     },
      //   },
      // },

      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
          },
          outlinedPrimary: {
            color: colors.dark,
            borderColor: colors.dark,
            "&:hover": {
              borderColor: colors.dark,
            },
          },
          //   root: {
          //     borderWidth: "2px",
          //     borderColor: colors.dark,
          //     fontWeight: 600,
          //     "&.Mui-disabled": {
          //       borderWidth: "2px",
          //       borderColor: colors.gray,
          //     },
          //     "&:hover": {
          //       borderWidth: "2px",
          //       boxShadow: `0 2px 2px 0 ${colors.gray}`,
          //     },
          //   },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            color: colors.dark,
          },
          colorPrimary: {
            color: colors.royal,
          },
        },
      },

      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: colors.dark,
            minWidth: 40,
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          colorPrimary: {
            color: colors.dark,
            background: "#EDF6FF",
          },
        },
      },
    },
  },
  ptBR
);
