import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import "./styles.sass";
import { useFormik } from "formik";
import * as yup from "yup";
import Logo from "../../global/images/logo.svg";
import PasswordRecoveryImage from "./images/password-recovery.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  useChangePasswordByRecoverySolicitateMutation,
  useConfirmEmailQuery,
  useValidatePasswordSolicitationQuery,
} from "../../services/AssisApi";
import { RTKFetchError } from "../../services/Helpers";
import {
  Visibility as ShowPasswordIcon,
  VisibilityOff as HidePasswordIcon,
  // Mail as MailIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { useState } from "react";
import { useSnackbar } from "notistack";

interface Props {}

export default function PasswordReset({}: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { code } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const {
    data: validateData,
    error: validateError,
    isLoading: validateIsLoading,
    isError: validateIsError,
    isSuccess: validateIsSuccess,
  } = useValidatePasswordSolicitationQuery(code || "");

  const [
    changePasswordByRecoverySolicitate,
    {
      isLoading: changePasswordByRecoverySolicitateIsLoading,
      isError: changePasswordByRecoverySolicitateIsError,
      error: changePasswordByRecoverySolicitateError,
      reset: changePasswordByRecoverySolicitateReset,
      data: changePasswordByRecoverySolicitateData,
    },
  ] = useChangePasswordByRecoverySolicitateMutation();

  const formValidationSchema = yup.object({
    password: yup
      .string()
      .required("Digite sua senha")
      .min(4, "Utilize uma senha com ${min} caracteres ou mais"),
    passwordConfirm: yup
      .string()
      .required("Confirme a senha")
      .oneOf([yup.ref("password"), null], "As senhas não conferem"),
  });

  const form = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: formValidationSchema,
    onSubmit: async (values) => {
      try {
        await changePasswordByRecoverySolicitate({
          code: code as string,
          password: values.password,
          passwordConfirm: values.passwordConfirm,
        });
        enqueueSnackbar("Senha recuperada com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
        form.resetForm();
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div className="password-recovery">
      <div className="header">
        <img src={Logo} alt="Logo Assis" />
        <Divider className="divider" />
      </div>
      <div className="main">
        <Grid container>
          <Grid item xs={6}>
            <img
              className="confirm-image"
              src={PasswordRecoveryImage}
              alt="Imagem de email confirmado"
            />
          </Grid>
          <Grid item xs={6}>
            <Paper className="content" elevation={4}>
              {validateIsLoading && <CircularProgress />}

              {validateIsError && (
                <Alert className="error-message" severity="error">
                  {RTKFetchError(validateError)?.message}
                </Alert>
              )}

              {validateIsSuccess && (
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={form.handleSubmit}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center">
                        Redefinir senha
                      </Typography>
                      <br></br>
                      {changePasswordByRecoverySolicitateData && (
                        <Alert
                          severity="success"
                          onClose={() => {
                            form.resetForm();
                            changePasswordByRecoverySolicitateReset();
                          }}
                        >
                          {changePasswordByRecoverySolicitateData.message}
                        </Alert>
                      )}

                      {changePasswordByRecoverySolicitateIsError && (
                        <Alert
                          severity="error"
                          onClose={() => {
                            changePasswordByRecoverySolicitateReset();
                          }}
                        >
                          {
                            RTKFetchError(
                              changePasswordByRecoverySolicitateError
                            )?.message
                          }
                        </Alert>
                      )}
                      <br></br>
                      <Typography variant="body1">
                        Insira sua nova senha. Após a confirmação, realize o
                        login novamente.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="password"
                        label="Nova senha"
                        placeholder="*****"
                        size="small"
                        fullWidth
                        margin="dense"
                        autoComplete="on"
                        disabled={changePasswordByRecoverySolicitateIsLoading}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          startAdornment: <LockIcon fontSize="small" />,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                disabled={
                                  changePasswordByRecoverySolicitateIsLoading
                                }
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <HidePasswordIcon />
                                ) : (
                                  <ShowPasswordIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={form.values.password}
                        onChange={form.handleChange}
                        error={
                          form.touched.password && Boolean(form.errors.password)
                        }
                        helperText={
                          form.touched.password && form.errors.password
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="passwordConfirm"
                        label="Confirme a nova senha"
                        placeholder="*****"
                        size="small"
                        fullWidth
                        margin="dense"
                        autoComplete="on"
                        disabled={changePasswordByRecoverySolicitateIsLoading}
                        type={showPasswordConfirm ? "text" : "password"}
                        InputProps={{
                          startAdornment: <LockIcon fontSize="small" />,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                disabled={
                                  changePasswordByRecoverySolicitateIsLoading
                                }
                                onClick={() =>
                                  setShowPasswordConfirm(!showPasswordConfirm)
                                }
                              >
                                {showPasswordConfirm ? (
                                  <HidePasswordIcon />
                                ) : (
                                  <ShowPasswordIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={form.values.passwordConfirm}
                        onChange={form.handleChange}
                        error={
                          form.touched.passwordConfirm &&
                          Boolean(form.errors.passwordConfirm)
                        }
                        helperText={
                          form.touched.passwordConfirm &&
                          form.errors.passwordConfirm
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={changePasswordByRecoverySolicitateIsLoading}
                      >
                        Redefinir senha
                        {changePasswordByRecoverySolicitateIsLoading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: "absolute",
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="footer">
                    <Grid item xs={12}>
                      <Button variant="text" onClick={() => navigate("/")}>
                        Voltar pra tela de login
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" align="center">
                        Ao continuar, estou de acordo com os{" "}
                        <a href="#">Termos de Uso e Política de Privacidade</a>{" "}
                        do Assis.
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Paper>
          </Grid>
        </Grid>
        {/* <Paper className="content" elevation={4}>
          {isLoading && (
            <>
              <Typography className="confirm-title" variant="h5">
                Confirmando o email
              </Typography>
              <CircularProgress />
            </>
          )}

          {isError && (
            <>
              <Alert className="error-message" severity="error">
                {RTKFetchError(error)?.message}
              </Alert>
              <Button variant="text" onClick={() => navigate('/login')}>
                Voltar para o Assis
              </Button>
            </>
          )}

          {isSuccess && (
            <>
              <img
                className="confirm-image"
                src={ConfirmEmailImage}
                alt="Imagem de email confirmado"
              />
              <Typography className="confirm-title" variant="h4">
                {data.message}
              </Typography>
              <Typography variant="body1" className="confirm-text">
                Obrigado por verificar seu endereço de e-mail. Agora você pode
                usar seu novo endereço de e-mail para fazer login.
              </Typography>
              <Button variant="text" onClick={() => navigate('/login')}>
                Voltar para o Assis
              </Button>
            </>
          )}
        </Paper> */}
      </div>
    </div>
  );
}
