import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
} from "@mui/icons-material";
import { ButtonBase, ButtonProps, IconButton, Typography } from "@mui/material";

import "./styles.sass";

interface Props extends ButtonProps {}

export default function MainFooter({ children }: Props) {
  return (
    <div className="main-footer">
      <div className="left">
        <ButtonBase className="link">Termos de uso</ButtonBase>
        <ButtonBase className="link">Politicas de privacidade</ButtonBase>
      </div>
      <div className="right">
        <Typography variant="caption" className="copyright">
          Copyright 2022 Assis. Todos os direitos reservados
        </Typography>
        <IconButton
          href="https://www.facebook.com/profile.php?id=100083451182191"
          target={"_blank"}
          color="primary"
        >
          <FacebookIcon />
        </IconButton>
        <IconButton
          href="https://www.instagram.com/assis.ai/"
          target={"_blank"}
          color="primary"
        >
          <InstagramIcon />
        </IconButton>
      </div>
    </div>
  );
}
