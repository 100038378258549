import React from "react";
import ReactDOM from "react-dom";
import "./index.sass";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { ThemeProvider, Typography } from "@mui/material";
import { theme } from "./global/theme";
import { SnackbarProvider } from "notistack";
import { TourProvider } from "@reactour/tour";

const steps = [
  {
    selector: "#sidebar-panel",
    content: (
      <Typography textAlign={"center"}>
        Aqui você poderá criar instâncias, fazer análises e gerar relatórios de
        diagnóstico referentes a cursos, turmas tutores e alunos de sua
        instituição
      </Typography>
    ),
    title: `Titutlo`,
  },
  {
    selector: "#sidebar-billing",
    content: (
      <Typography textAlign={"center"}>
        Nas faturas e pagamentos você pode acompanhar o resumo das suas faturas
        e adicionar novas formas de pagamento.
      </Typography>
    ),
  },
  {
    selector: "#sidebar-userSettings",
    content: (
      <Typography textAlign={"center"}>
        Aqui você terá acesso a todas as suas informações de cadastro de usuário
        podendo alterá-las quando preferir!
      </Typography>
    ),
  },
  {
    selector: "#sidebar-plan",
    content: (
      <Typography textAlign={"center"}>
        Esse é o seu tempo restante de avaliação gratuita. Após seu esgotamento
        você só conseguirá utilizar o Assis assinando um plano superior.
      </Typography>
    ),
  },
  // {
  //   selector: "#header-notification",
  //   content: (
  //     <Typography textAlign={"center"}>
  //       Aqui iremos te atualizar sobre tudo que você precisa saber.
  //     </Typography>
  //   ),
  // },
  {
    selector: ".add-instance",
    content: (
      <Typography textAlign={"center"}>
        Nesse botão você poderá criar uma instância e dentro dela gerar análises
        e ter acesso a várias informações de diagnótico da sua instituição.
      </Typography>
    ),
  },
];

const styles = {
  popover: (base: any, state: any) => {
    return {
      ...base,
      borderRadius: 10,
    };
  },
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            maxSnack={3}
          >
            <TourProvider styles={styles} steps={steps}>
              <App />
            </TourProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
