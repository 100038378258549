import {
  Alert,
  Button,
  ButtonProps,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import "./styles.sass";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Visibility as ShowPasswordIcon,
  VisibilityOff as HidePasswordIcon,
  Mail as MailIcon,
  RadioButtonUnchecked as UncheckIcon,
  CheckCircle as CheckIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { ResponseError } from "../../types";
import { useEffect, useState } from "react";
import GoogleLoginButton from "../GoogleLoginButton";
import { useLoginMutation } from "../../services/AssisApi";
import { useLocalStorage } from "../../hooks/LocalStorage";
import { RTKFetchError } from "../../services/Helpers";

interface Props extends ButtonProps {}

export default function LoginForm({}: Props) {
  const [showPassword, setShowPassword] = useState(false);
  const [login, { isLoading, isError, data, error, reset }] =
    useLoginMutation();
  const [token, setToken] = useLocalStorage("token");

  const formValidationSchema = yup.object({
    email: yup
      .string()
      .required("Digite seu email")
      .email("Formato de email inválido"),
    password: yup
      .string()
      .required("Digite sua senha")
      .min(4, "Utilize uma senha com ${min} caracteres ou mais"),
  });

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: formValidationSchema,
    onSubmit: async (values) => {
      try {
        await login({
          email: values.email,
          password: values.password,
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (data?.token) {
      setToken(data.token);
      window.location.reload();
    }
  }, [data]);

  return (
    <form noValidate autoComplete="off" onSubmit={form.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GoogleLoginButton />
        </Grid>
        <Grid item xs={12}>
          <div className="divider">
            <span>ou</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          {isError && (
            <Alert severity="error" onClose={() => reset()}>
              {RTKFetchError(error)?.message}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="email"
            label="Email"
            placeholder="seu@email.com"
            size="small"
            fullWidth
            disabled={isLoading}
            InputProps={{
              startAdornment: <MailIcon fontSize="small" />,
            }}
            value={form.values.email}
            onChange={form.handleChange}
            error={form.touched.email && Boolean(form.errors.email)}
            helperText={form.touched.email && form.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="password"
            label="Senha"
            placeholder="*****"
            size="small"
            fullWidth
            margin="normal"
            autoComplete="on"
            disabled={isLoading}
            type={showPassword ? "text" : "password"}
            InputProps={{
              startAdornment: <LockIcon fontSize="small" />,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    disabled={isLoading}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={form.values.password}
            onChange={form.handleChange}
            error={form.touched.password && Boolean(form.errors.password)}
            helperText={form.touched.password && form.errors.password}
          />
        </Grid>
        {/* <Grid item xs={12} className="keep-connected-wrapper">
            <FormControlLabel
              control={
                <Checkbox
                  checked
                  icon={<UncheckIcon />}
                  checkedIcon={<CheckIcon />}
                />
              }
              label="Mantenha-me conectado."
            />
          </Grid> */}
        <Grid item xs={12} className="keep-connected-wrapper">
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={isLoading}
          >
            Entrar
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                }}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
