import { Fab, Paper } from "@mui/material";
import Breadcrumbs from "../Breadcrumbs";
import { ReactNode, useEffect } from "react";

import "./styles.sass";
import { useLocation } from "react-router-dom";
import { ReactRoutesState } from "../../types";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

interface Props {
  pageTitle?: string;
  children: ReactNode;
  showBreadcrumb?: boolean;
}

export default function Screen({
  // pageTitle,
  children,
  showBreadcrumb = true,
}: Props) {
  const location = useLocation();
  const state = location.state as ReactRoutesState;

  useEffect(() => {
    // if (pageTitle) document.title = pageTitle;
    document.title = `${process.env.REACT_APP_TITLE}${
      state?.pageTitle ? " > " + state.pageTitle : ""
    }`;
  }, []);

  return (
    <div className="screen">
      {showBreadcrumb && (
        <Paper className="screen-header" elevation={3}>
          <Breadcrumbs />
        </Paper>
      )}
      <Paper className="screen-body" elevation={3}>
        {children}
      </Paper>
      <Fab
        variant="extended"
        href="https://api.whatsapp.com/send?phone=558581679383"
        target="_blank"
        color={"primary"}
        id={"support-fab"}
      >
        <WhatsAppIcon sx={{ mr: 1 }} />
        Suporte
      </Fab>
    </div>
  );
}
