import { createSlice } from "@reduxjs/toolkit";
import { AssisApi } from "../../services/AssisApi";
import { GetMeResponse } from "../../types";

const initialState = {
  authenticated: Boolean(localStorage.getItem("token")),
  authenticatedUser: {} as GetMeResponse,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticatedUserAction: (state, { payload }) => {
      state.authenticated = Boolean(localStorage.getItem("token"));
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      AssisApi.endpoints.getMe.matchFulfilled,
      (state, { payload }) => {
        state.authenticatedUser = payload as GetMeResponse;
      }
    );
  },
});

export const { setAuthenticatedUserAction } = authSlice.actions;

export default authSlice.reducer;
