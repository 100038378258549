import { createSlice } from "@reduxjs/toolkit";
import { AssisApi } from "../../services/AssisApi";
import { Batch, Instance } from "../../types";

const initialState = {
  snackbarMessages: [],
  selectedInstance: {} as Instance,
  instanceBatchs: [] as Batch[],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    addSnackbar: (state, { payload }) => {},
    selectInstance: (state, { payload }) => {
      state.selectedInstance = payload;
      state.instanceBatchs = [] as Batch[];
      return state;
    },
    // loginAction: (state, { payload }) => {
    //   state.authenticated = true;
    //   state.authenticatedUser = { email: payload.email };
    // },
    // logoutAction: (state) => {
    //   state.authenticated = initialState.authenticated;
    //   state.authenticatedUser = initialState.authenticatedUser;
    // },
    // updateAuthenticatedUserAction: (state, { payload }) => {
    //   state.authenticatedUser = payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      AssisApi.endpoints.getIntanceBatchs.matchFulfilled,
      (state, { payload }) => {
        state.instanceBatchs = payload as Batch[];
      }
    );
  },
});

export const { addSnackbar, selectInstance } = appSlice.actions;

export default appSlice.reducer;
