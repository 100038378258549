import React, { CSSProperties, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Alert,
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import ContinueIcon from "@mui/icons-material/ArrowForward";
import { ResponseError } from "../../types";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetMeQuery, useSelfRemoveMutation } from "../../services/AssisApi";

import "./styles.sass";

interface Props {
  style?: CSSProperties;
}

export default function UserDeleteComfirm({ style }: Props) {
  const navigate = useNavigate();

  const { data: me, error: meError, isLoading: meIsLoading } = useGetMeQuery();

  const [step, setStep] = useState(1);
  const [feedbackOption, setFeedbackOption] = useState(0);

  const [response, setResponse] = useState("");
  const [error, setError] = useState<ResponseError>({} as ResponseError);

  const [rmv, rmvData] = useSelfRemoveMutation();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Digite um email valido")
      .equals([me?.email], "Email incorreto")
      .required("Informe seu email para a confirmação"),

    // .email()
    // .required("Informe o nome do ambiente")
    // .min(3, "O nome do ambiente deve conter pelo menos ${min} caracteres")
    // .max(25, "O nome do ambiente deve conter no máximo ${max} caracteres"),
  });

  const form = useFormik({
    initialValues: {
      email: "",
    },
    initialTouched: { email: true },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await rmv("");
      localStorage.removeItem("token");
      window.location.reload();
    },
  });

  const onClose = (e: object, reason?: string) => {
    if (reason !== "backdropClick") navigate(-1);
  };

  // const validate = async () => {
  //   await form.validateForm();
  //   console.log(form);
  //   // setStep(2);
  // };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      className="user-delete-confirm-screen"
      disableEscapeKeyDown
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={form.handleSubmit}
      >
        {step === 1 && (
          <DialogContent className="content">
            <Typography variant="h6">Apagar usuário</Typography>
            <Typography variant="subtitle2">Ao apagar o usuário:</Typography>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Todos os ambientes serão excluídas
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Todas as análises serão apagadas
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Os resultados de todas as suas análises serão apagados
                </Typography>
              </li>
              {/* <li>
                <Typography variant="subtitle1">
                  Você não poderá criar outra conta com o mesmo usuário
                </Typography>
              </li> */}
            </ul>

            {response && (
              <Alert
                severity="success"
                variant="filled"
                onClose={() => setResponse("")}
              >
                {response}
              </Alert>
            )}
            {error.message && (
              <Alert
                severity="error"
                variant="filled"
                onClose={() => setError({} as ResponseError)}
              >
                {error.message}
              </Alert>
            )}
            <Typography variant="subtitle2" className="alert-text">
              Esta ação não pode ser desfeita! Confirme seu email para
              continuar.
            </Typography>
            <TextField
              name="email"
              hiddenLabel
              placeholder="Insira seu email para continuar"
              size="small"
              fullWidth
              // disabled={loading}
              value={form.values.email}
              onChange={form.handleChange}
              error={form.touched.email && Boolean(form.errors.email)}
              helperText={form.touched.email && form.errors.email}
            />
            <div className="footer">
              <Button
                className="button"
                onClick={onClose}
                variant="contained"
                // disabled={loading}
              >
                Cancelar
                {/* {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                    }}
                  />
                )} */}
              </Button>
              <Button
                className="button"
                color="error"
                variant="contained"
                // disabled={loading}
                onClick={async () => {
                  // await validate();
                  // setTimeout(() => {
                  if (form.isValid && !form.isValidating) {
                    setStep(2);
                  }
                  // }, 400);
                }}
              >
                Excluir
                {/* {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                    }}
                  />
                )} */}
              </Button>
            </div>
          </DialogContent>
        )}
        {step === 2 && (
          <DialogContent className="content">
            <Typography variant="h6">Envie seu feedback</Typography>
            <Typography variant="subtitle2">
              Como você classificaria sua experiência com o Assis?
            </Typography>

            <div className="feedback-options">
              <ButtonBase
                className={`feedback-item ${
                  feedbackOption === 1 ? "active" : ""
                }`}
                onClick={() => {
                  setFeedbackOption(1);
                }}
              >
                <div className="icon">😣</div>
                <div className="text">Muito ruim</div>
              </ButtonBase>
              <ButtonBase
                className={`feedback-item ${
                  feedbackOption === 2 ? "active" : ""
                }`}
                onClick={() => {
                  setFeedbackOption(2);
                }}
              >
                <div className="icon">😥</div>
                <div className="text">Ruim</div>
              </ButtonBase>
              <ButtonBase
                className={`feedback-item ${
                  feedbackOption === 3 ? "active" : ""
                }`}
                onClick={() => {
                  setFeedbackOption(3);
                }}
              >
                <div className="icon">🙂</div>
                <div className="text">Boa</div>
              </ButtonBase>
              <ButtonBase
                className={`feedback-item ${
                  feedbackOption === 4 ? "active" : ""
                }`}
                onClick={() => {
                  setFeedbackOption(4);
                }}
              >
                <div className="icon">😄</div>
                <div className="text">Muito boa</div>
              </ButtonBase>
              <ButtonBase
                className={`feedback-item ${
                  feedbackOption === 5 ? "active" : ""
                }`}
                onClick={() => {
                  setFeedbackOption(5);
                }}
              >
                <div className="icon">😍</div>
                <div className="text">Excelente</div>
              </ButtonBase>
            </div>

            <div className="footer">
              <Button
                className="button"
                variant="contained"
                disabled={!!!feedbackOption}
                onClick={() => {
                  setStep(3);
                }}
              >
                Enviar feedback
                <ContinueIcon />
                {/* {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                    }}
                  />
                )} */}
              </Button>
            </div>
          </DialogContent>
        )}

        {step === 3 && (
          <DialogContent className="content">
            <Typography variant="h6">Você apagou o usuário</Typography>
            <br />
            <Typography variant="subtitle2">
              Muito obrigado por responder a esta pesquisa e nos ajudar a
              melhorar o Assis para você!
            </Typography>
            <br />
            <br />

            <div className="footer">
              <Button
                className="button"
                variant="contained"
                onClick={async () => {
                  await form.submitForm();
                }}
                // disabled={loading}
              >
                Finalizar
                <ContinueIcon />
                {/* {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                    }}
                  />
                )} */}
              </Button>
            </div>
          </DialogContent>
        )}
      </Box>
    </Dialog>
  );
}
