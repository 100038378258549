import React from "react";
// import { useDispatch } from "react-redux";

import { ButtonBase, Grid, Typography, Paper, Stack } from "@mui/material";

// import { Creators as AppActions } from "../../store/ducks/app";
import {
  AccessTimeFilled,
  TransferWithinAStation,
  HourglassEmpty,
  Tungsten,
  InfoOutlined,
} from "@mui/icons-material";
import "./style.sass";

const MetricBlock = (props) => {
  // const dispatch = useDispatch()

  // const setSelectStudent = (studentIds) => {
  //   dispatch(AppActions.setSelectedStudents(studentIds.map(studentId => ({studentId}))))
  // }

  const selectIcon = () => {
    switch (props.icon) {
      case "AccessTimeFilled":
        return <AccessTimeFilled />;
      case "TransferWithinAStation":
        return <TransferWithinAStation />;
      case "Tungsten":
        return <Tungsten sx={{ transform: "rotate(180degs)" }} />;
      case "HourglassEmpty":
        return <HourglassEmpty />;
      default:
        break;
    }
  };

  return (
    <Grid container className={"metric-block block"} padding={2}>
      <Grid item xs={12}>
        <Stack direction={"row"} spacing={1}>
          {selectIcon()}
          <Typography variant="subtitle2" className="title">
            {props.title}
          </Typography>
          <InfoOutlined
            onMouseEnter={(e) => props.handlePopoverOpen(e, props.tooltip)}
            onMouseLeave={props.handlePopoverClose}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" className="subtitle">
          {props.subtitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.classifications.map((classification, key) => (
          <ButtonBase
            key={key}
            // onClick={() => {setSelectStudent(classification.studentIds)}}
            // sx={{ cursor: "pointer" }}
            onClick={() =>
              props.handleListStudents({
                title: `${props.title} - ${classification.title}`,
                ids: classification.studentIds,
              })
            }
            className="classification-button"
          >
            <div
              className="color-bar"
              style={{ backgroundColor: classification.color }}
            ></div>
            <Typography variant="subtitle2" className="title">
              {classification.title}
            </Typography>
            <Typography variant="caption">
              Total de {classification.total} (
              {classification.percent.toFixed(2)}%) | Média de{" "}
              {classification.avg.toFixed(2)}
            </Typography>
          </ButtonBase>
        ))}
      </Grid>
    </Grid>
  );
};

MetricBlock.defaultProps = {
  className: "",
  title: "",
  subtitle: "",
  classifications: [],
};

export default MetricBlock;
