import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import "./styles.sass";
import Logo from "../../global/images/logo.svg";
import ConfirmEmailImage from "./images/confirm-email.png";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirmEmailQuery } from "../../services/AssisApi";
import { RTKFetchError } from "../../services/Helpers";

interface Props {}

export default function ConfirmEmail({}: Props) {
  const navigate = useNavigate();
  const { code } = useParams();
  const { data, error, isLoading, isError, isSuccess } = useConfirmEmailQuery(
    code || ""
  );

  return (
    <div className="confirm-email">
      <div className="header">
        <img src={Logo} alt="Logo Assis" />
        <Divider className="divider" />
      </div>
      <div className="main">
        <Paper className="content" elevation={4}>
          {isLoading && (
            <>
              <Typography className="confirm-title" variant="h5">
                Confirmando o email
              </Typography>
              <CircularProgress />
            </>
          )}

          {isError && (
            <>
              <Alert className="error-message" severity="error">
                {RTKFetchError(error)?.message}
              </Alert>
              <Button variant="text" onClick={() => navigate("/login")}>
                Voltar para o Assis
              </Button>
            </>
          )}

          {isSuccess && (
            <>
              <img
                className="confirm-image"
                src={ConfirmEmailImage}
                alt="Imagem de email confirmado"
              />
              <Typography className="confirm-title" variant="h4">
                {data.message}
              </Typography>
              <Typography variant="body1" className="confirm-text">
                Obrigado por verificar seu endereço de e-mail. Agora você já
                pode realizar login na plataforma ASSIS.
              </Typography>
              <Button variant="text" onClick={() => navigate("/login")}>
                Voltar para o Assis
              </Button>
            </>
          )}
        </Paper>
        {/* <Paper className="content" elevation={4}>
          <img className="confirm-image" src={ConfirmEmailImage} alt="Imagem de email confirmado"/>
          <Typography className="confirm-title" variant="h4">Seu email foi verificado!</Typography>
          <Typography variant="body1" className="confirm-text">Obrigado por verificar seu endereço de e-mail. Agora você pode usar seu novo endereço de
e-mail para fazer login.</Typography>
<Button variant="text">Voltar para o Assis</Button>
        </Paper> */}
      </div>
    </div>
  );
}
