import Screen from "../../components/Screen";
import "./styles.sass";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Check";

import ShowPasswordIcon from "@mui/icons-material/Visibility";
import HidePasswordIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetMeQuery,
  usePasswordRecoverySolicitateMutation,
  useSendChangeEmailMutation,
  useUpdateUserMutation,
} from "../../services/AssisApi";
import { useSnackbar } from "notistack";
import { Flag, WorkspacePremium } from "@mui/icons-material";

// interface Props {}

export default function UserSetting() {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [UpdateUser, updateUserData] = useUpdateUserMutation();
  const [SendChangeEmail, sendChangeEmailData] = useSendChangeEmailMutation();
  const [passwordRecoverySolicitate, passwordRecoveryResponse] =
    usePasswordRecoverySolicitateMutation();
  const {
    data: me,
    error: meError,
    isLoading: meIsLoading,
    refetch,
  } = useGetMeQuery();

  useEffect(() => {
    if (sendChangeEmailData.isError) {
      console.log(sendChangeEmailData);
      closeSnackbar("loading");
      if (sendChangeEmailData.error && "data" in sendChangeEmailData.error) {
        const data = sendChangeEmailData.error.data as Error;
        enqueueSnackbar(data?.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Houve um erro, tente novamente!", {
          variant: "error",
        });
      }
    }
    if (sendChangeEmailData.isSuccess) {
      closeSnackbar("loading");
      setEditName(false);
      setEditEmail(false);
      setEditContact(false);
      enqueueSnackbar(
        "Email enviado, alteração ocorrerá após confirmação no destinatário",
        { variant: "success" }
      );
    }
  }, [sendChangeEmailData.isError, sendChangeEmailData.isSuccess]);

  const handleEmail = async () => {
    const email = document.querySelector(
      'input[name="email"'
    ) as HTMLInputElement;
    enqueueSnackbar("Enviando email", { variant: "info", key: "loading" });
    if (email) await SendChangeEmail({ email: email.value });
  };

  const handleChange = async (name: string) => {
    const input = document.querySelector(
      `input[name="${name}"`
    ) as HTMLInputElement;

    const data: Record<string, unknown> = {};
    data[`${name}`] = input.value;
    enqueueSnackbar("Alterando dados", { variant: "info", key: "loading" });
    if (input) await UpdateUser(data);
    await refetch();
    closeSnackbar("loading");
    setEditName(false);
    setEditEmail(false);
    setEditContact(false);
    enqueueSnackbar("Dados alterados com sucesso!", { variant: "success" });

    // window.location.reload();
  };

  const handlePassword = async () => {
    try {
      const email: string = me?.email || "";
      await passwordRecoverySolicitate({ email });
      enqueueSnackbar("Enviamos um link de redefinição para o seu email!", {
        variant: "success",
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        "Houve um erro na solicitação, por favor tente novamente!",
        { variant: "error" }
      );
    }
  };

  return (
    <div className="user-settings-screen">
      <Screen>
        {/* <Typography variant="body1" className="title">
          elonmusk@spacex.com
        </Typography>
        <Typography variant="body1" className="title">
          Elon Musk
        </Typography>

        <br /> */}
        <Stack direction={"row"} spacing={2}>
          {me?.plan === "premium" ? (
            <>
              <Typography variant="h6">Perfil Premium</Typography>
              <WorkspacePremium className={"premium-color"} />
            </>
          ) : (
            <>
              <Typography variant="h6">Perfil Gratuito</Typography>{" "}
              <Flag color={"primary"} />
            </>
          )}
        </Stack>
        <Typography variant="body1">Suas informações pessoais</Typography>

        <Paper elevation={3} className="person-info">
          <Typography variant="h6">Informações pessoais</Typography>
          <br />
          <Grid container spacing={1}>
            <Grid className="row" item xs={12}>
              <div className="label">
                <Typography variant="body1">Foto de perfil:</Typography>
              </div>
              <div className="field">
                <Avatar
                  sizes="small"
                  alt={me?.name.split(" ")[0]}
                  src={me?.picture}
                />
              </div>
            </Grid>
            <Grid className="row" item xs={12}>
              <div className="label">
                <Typography variant="body1">Nome:</Typography>
              </div>
              <div className="field">
                {!editName && (
                  <div className="disabled-edit">
                    <Typography className="text" variant="body1">
                      {me?.name}
                    </Typography>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => setEditName(true)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </div>
                )}
                {editName && (
                  <div className="enabled-edit">
                    <TextField
                      className="input"
                      name="name"
                      hiddenLabel
                      placeholder="Informe seu nome"
                      size="small"
                      defaultValue={me?.name}
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      // value={form.values.email}
                      // onChange={form.handleChange}
                      // error={form.touched.email && Boolean(form.errors.email)}
                      // helperText={form.touched.email && form.errors.email}
                    />
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => setEditName(false)}
                    >
                      <CancelIcon color="error" fontSize="small" />
                    </IconButton>
                    <IconButton size="small">
                      <SaveIcon
                        color="success"
                        fontSize="small"
                        onClick={() => handleChange("name")}
                      />
                    </IconButton>
                  </div>
                )}
              </div>
            </Grid>
            <Grid className="row" item xs={12}>
              <div className="label">
                <Typography variant="body1">Email:</Typography>
              </div>
              <div className="field">
                {!editEmail && (
                  <div className="disabled-edit">
                    <Typography className="text" variant="body1">
                      {me?.email}
                    </Typography>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => setEditEmail(true)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </div>
                )}
                {editEmail && (
                  <div className="enabled-edit">
                    <TextField
                      className="input"
                      name="email"
                      hiddenLabel
                      placeholder="seu@email.com"
                      size="small"
                      defaultValue={me?.email}
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      // value={form.values.email}
                      // onChange={form.handleChange}
                      // error={form.touched.email && Boolean(form.errors.email)}
                      // helperText={form.touched.email && form.errors.email}
                    />
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => setEditEmail(false)}
                    >
                      <CancelIcon color="error" fontSize="small" />
                    </IconButton>
                    <IconButton size="small">
                      <SaveIcon
                        color="success"
                        fontSize="small"
                        onClick={handleEmail}
                      />
                    </IconButton>
                  </div>
                )}
              </div>
            </Grid>
            <Grid className="row" item xs={12}>
              <div className="label">
                <Typography variant="body1">Contato:</Typography>
              </div>
              <div className="field">
                {!editContact && (
                  <div className="disabled-edit">
                    <Typography className="text" variant="body1">
                      {me?.whatsapp}
                    </Typography>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => setEditContact(true)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </div>
                )}
                {editContact && (
                  <div className="enabled-edit">
                    <TextField
                      className="input"
                      name="whatsapp"
                      hiddenLabel
                      placeholder="00 00000-0000"
                      size="small"
                      defaultValue={me?.whatsapp}
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      // value={form.values.email}
                      // onChange={form.handleChange}
                      // error={form.touched.email && Boolean(form.errors.email)}
                      // helperText={form.touched.email && form.errors.email}
                    />
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => setEditContact(false)}
                    >
                      <CancelIcon color="error" fontSize="small" />
                    </IconButton>
                    <IconButton size="small">
                      <SaveIcon
                        color="success"
                        fontSize="small"
                        onClick={() => handleChange("whatsapp")}
                      />
                    </IconButton>
                  </div>
                )}
              </div>
            </Grid>
            <Grid className="row" item xs={12}>
              <div className="label">
                <Typography variant="body1">Senha:</Typography>
              </div>
              <div className="field">
                <Button
                  disabled={passwordRecoveryResponse.isLoading}
                  onClick={handlePassword}
                  variant="contained"
                >
                  Redefinir senha pelo email
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>

        <br />

        <Typography variant="h6">Exclusão</Typography>
        <Typography variant="body1">Ações irreversíveis</Typography>

        <Paper elevation={3} className="delete-info">
          <Typography variant="h6">Apagar usuário</Typography>
          <Typography variant="body1">
            Essa ação não pode ser desfeita.
          </Typography>
          <br />
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              navigate("/user/delete/confirm", {
                state: { backgroundLocation: location },
              });
            }}
          >
            Apagar usuário
          </Button>
        </Paper>
      </Screen>
    </div>
  );
}
