import { useDispatch, useSelector } from "react-redux";
import { Recommender } from "../../types";
import { RootState } from "../../store";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close,
  Event,
  Groups,
  MenuBook,
  OpenInNew,
  Percent,
  Person,
  RadioButtonChecked,
  Recommend,
  School,
  Send,
  Timeline,
  Warning,
} from "@mui/icons-material";
import { selectRecommendation } from "../../store/slices/recommendation";
import moment from "moment";
import {
  DataGrid,
  GridCellParams,
  GridColumnHeaderParams,
} from "@mui/x-data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetRecommendersHistoryMutation } from "../../services/AssisApi";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";

export default function RecommendationDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instanceId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const selectedRecommendation: Recommender = useSelector(
    (state: RootState) => state.recommendations.selectedRecommendation
  );
  const [openSend, setOpenSend] = useState(false);

  useEffect(() => {
    if (selectedRecommendation.class)
      recommendersHistory({
        "class.classId": selectedRecommendation.class.classId,
        instance: instanceId as string,
        type: selectedRecommendation.type,
      });
  }, [selectedRecommendation]);

  const [recommendersHistory, recommendersHistoryResult] =
    useGetRecommendersHistoryMutation();

  const handleClose = () => {
    dispatch(selectRecommendation({} as Recommender));
    navigate(-1);
  };

  const formatConclusion = () => {
    let text = "";
    if (selectedRecommendation) {
      if (moment().diff(moment(selectedRecommendation.class?.startDate)) <= 0) {
        text += "0";
      } else if (
        moment().diff(moment(selectedRecommendation.class?.endDate)) >= 0
      ) {
        text += "100";
      } else {
        text += (
          (moment().diff(moment(selectedRecommendation.class?.startDate)) /
            moment(selectedRecommendation.class?.endDate).diff(
              moment(selectedRecommendation.class?.startDate)
            )) *
          100
        ).toFixed(0);
      }
      text += `% (${moment(selectedRecommendation.class?.endDate)
        .add(3, "hours")
        .format("DD/MM/YYYY")})`;
    }
    return text;
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Informe um email válido")
      .required("Informe seu email para a confirmação"),
  });

  const form = useFormik({
    initialValues: {
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        // await deleteBatch({
        //   instanceId: instanceId as string,
        //   batchId: batchId as string,
        // });
        // if (deleteBatchIsError) throw deleteBatchError;
        form.resetForm();
        enqueueSnackbar("Enviado com sucesso", { variant: "success" });
        // window.location.reload();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleCloseSend = () => {
    form.resetForm();
    setOpenSend(false);
  };

  const handleOpenSend = () => {
    setOpenSend(true);
  };

  return (
    <>
      <Dialog fullScreen open={!!selectedRecommendation.name}>
        <AppBar sx={{ position: "relative", borderRadius: 0 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Detalhes da recomendação
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12}>
            <Fab onClick={handleOpenSend}>
              <Send />
            </Fab>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid
                  item
                  xs={2}
                  sx={{ borderRight: 0.5, borderColor: "#333333" }}
                >
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Recommend />
                    <Typography>Recomendação</Typography>
                  </Stack>
                  <Typography>{selectedRecommendation.type}</Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{ borderRight: 0.5, borderColor: "#333333" }}
                >
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <MenuBook />
                    <Typography>Curso</Typography>
                  </Stack>
                  <Typography>{selectedRecommendation.course?.name}</Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{ borderRight: 0.5, borderColor: "#333333" }}
                >
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Groups />
                    <Typography>Turma</Typography>
                  </Stack>
                  <Typography>{selectedRecommendation.class?.name}</Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ borderRight: 0.5, borderColor: "#333333" }}
                >
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <School />
                    <Typography>Tutor</Typography>
                  </Stack>
                  <Typography>{selectedRecommendation.tutor?.name}</Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{ borderRight: 0.5, borderColor: "#333333" }}
                >
                  <Tooltip title="A data em que a análise foi enviada">
                    <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                      <Event />
                      <Typography>Data</Typography>
                    </Stack>
                  </Tooltip>
                  <Typography>
                    {moment(selectedRecommendation.date).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ borderRight: 0.5, borderColor: "#333333" }}
                >
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Percent />
                    <Typography>Conclusão da turma</Typography>
                  </Stack>
                  <Typography>{formatConclusion()}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Percent />
                    <Typography>Análise que disparou a recomendação</Typography>
                  </Stack>
                  <Link
                    to={`/i/${selectedRecommendation.instance}/analises/${selectedRecommendation.batch}/result`}
                  >
                    <Stack direction={"row"} spacing={1}>
                      <Typography>
                        {moment(selectedRecommendation.date)
                          .add(3, "hours")
                          .format("DD/MM/YYYY")}
                      </Typography>
                      <OpenInNew />
                    </Stack>
                    <Typography></Typography>
                  </Link>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "#FFDBDB",
                borderRadius: "12px 12px 0px 0px",
                p: 2,
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <Warning sx={{ color: "#FF3636" }} />
                <Typography>{selectedRecommendation.problem}</Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                backgroundColor: "#D8EBFF",
                borderRadius: "0px 0px 12px 12px",
                p: 2,
              }}
            >
              <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }}>
                <Recommend />
                <Typography>Recomendação</Typography>
              </Stack>
              <Typography>{selectedRecommendation.recommendation}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <DataGrid
              sx={{ height: "61vh" }}
              rows={
                selectedRecommendation.list
                  ? selectedRecommendation.list.map((r, index) => {
                      const row = r.split(",");
                      return {
                        id: index,
                        aluno: row[0],
                        media: row[1],
                        tipo: row[2],
                      };
                    })
                  : []
              }
              columns={[
                {
                  field: "aluno",
                  headerName: "Aluno",
                  flex: 1.5,
                  renderCell: (params: GridCellParams<string>) => (
                    <Tooltip title={`${params.row?.aluno || ""}`}>
                      <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                        {params.row?.aluno || ""}
                      </Typography>
                    </Tooltip>
                  ),
                  renderHeader: (params: GridColumnHeaderParams) => (
                    <Stack spacing={1} direction={"row"}>
                      <Person />
                      <Typography>Aluno</Typography>
                    </Stack>
                  ),
                },
                {
                  field: "media",
                  headerName: "Média de avaliação",
                  flex: 1,
                  renderCell: (params: GridCellParams<string>) => (
                    <Tooltip title={`${params.row?.media || ""}`}>
                      <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                        {params.row?.media || ""}
                      </Typography>
                    </Tooltip>
                  ),
                  renderHeader: (params: GridColumnHeaderParams) => (
                    <Stack spacing={1} direction={"row"}>
                      <Timeline />
                      <Typography>Média de avaliação</Typography>
                    </Stack>
                  ),
                },
                {
                  field: "tipo",
                  headerName: "Problema",
                  flex: 1,
                  renderCell: (params: GridCellParams<string>) => (
                    <Tooltip title={`${params.row?.tipo || ""}`}>
                      <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                        {params.row?.tipo || ""}
                      </Typography>
                    </Tooltip>
                  ),
                  renderHeader: (params: GridColumnHeaderParams) => (
                    <Stack spacing={1} direction={"row"}>
                      <RadioButtonChecked />
                      <Typography>Problema</Typography>
                    </Stack>
                  ),
                },
              ]}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            {recommendersHistoryResult.data?.length === 0 ? (
              <Typography variant={"h6"}>nenhuma recomendação</Typography>
            ) : (
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={recommendersHistoryResult.data?.map(
                  (row: any[], index: number) => {
                    if (index === 0) return row;
                    return [new Date(row[0]), ...row.slice(1)];
                  }
                )}
                loader={<CircularProgress />}
                options={{
                  legend: "top",
                  hAxis: {
                    title: "Data",
                    format: "d/MM/yyyy",
                  },
                  vAxis: {
                    title: "Alunos",
                  },
                  focusTarget: "category",
                  explorer: {
                    axis: "horizontal",
                    keepInBounds: true,
                    maxZoomIn: 4.0,
                  },
                }}
                legendToggle
              />
            )}
          </Grid>
        </Grid>
      </Dialog>
      <Dialog open={openSend} maxWidth={"md"} fullWidth>
        <Grid
          container
          spacing={2}
          p={2}
          component={"form"}
          onSubmit={form.handleSubmit}
        >
          <Grid item xs={12}>
            <Stack sx={{ alignItems: "center" }} direction={"row"} spacing={1}>
              <Send />
              <Typography sx={{ flexGrow: 1 }}>Encaminhar</Typography>
              <IconButton onClick={handleCloseSend}>
                <Close />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h6"}>
              Tutor: {selectedRecommendation.tutor?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid
                  item
                  xs={6}
                  sx={{ borderRight: 0.5, borderColor: "#333333" }}
                >
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Recommend />
                    <Typography>Recomendação</Typography>
                  </Stack>
                  <Typography>{selectedRecommendation.type}</Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ borderRight: 0.5, borderColor: "#333333" }}
                >
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <MenuBook />
                    <Typography>Curso</Typography>
                  </Stack>
                  <Typography>{selectedRecommendation.course?.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                    <Groups />
                    <Typography>Turma</Typography>
                  </Stack>
                  <Typography>{selectedRecommendation.class?.name}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              hiddenLabel
              placeholder="Insira o email que deseja encaminhar"
              size="small"
              fullWidth
              value={form.values.email}
              onChange={form.handleChange}
              error={form.touched.email && Boolean(form.errors.email)}
              helperText={
                "email usado por ultimo, ao enviar notificação salvar esse email para sugestão"
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="message"
              hiddenLabel
              placeholder="Pode enviar uma mensagem junto aos dados"
              size="small"
              fullWidth
              multiline
              value={form.values.message}
              onChange={form.handleChange}
              error={form.touched.message && Boolean(form.errors.message)}
              // helperText={"email usado por ultimo, ao enviar notificação salvar esse email para sugestão"}
            />
          </Grid>
          <Grid item xs={7}></Grid>
          <Grid item xs={5}>
            <Button variant="contained" type="submit" fullWidth>
              Enviar mensagem
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
