import { Chart, ChartWrapperProps } from "react-google-charts";

import "./style.sass";

const DonutChart = (props?: any) => {
  return (
    <Chart
      width="100%"
      height="100%"
      className="custom_DonutChart"
      data={[
        ["Alunos", "Quantidade"],
        ["Indicados", 11],
        ["Não Indicados", 100 - 11],
      ]}
      options={{
        title: "",
        pieHole: 0.6,
        is3D: false,
        legend: "none",
        pieSliceText: "none",
        enableInteractivity: false,
        colors: ["#E43C3C", "#EAEAEA"],
        chartArea: {
          top: 0,
          left: 10,
          right: 10,
          bottom: 0,
        },
      }}
      {...props}
      chartType="PieChart"
    />
  );
};

export default DonutChart;
