import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import "./styles.sass";
import { useFormik } from "formik";
import * as yup from "yup";
import Logo from "../../global/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { usePasswordRecoverySolicitateMutation } from "../../services/AssisApi";
import { RTKFetchError } from "../../services/Helpers";
import MailIcon from "@mui/icons-material/Mail";

interface Props {}

export default function PasswordRecovery({}: Props) {
  const navigate = useNavigate();

  const [
    passwordRecoverySolicitate,
    {
      isLoading: passwordRecoverySolicitateIsLoading,
      isError: passwordRecoverySolicitateIsError,
      error: passwordRecoverySolicitateError,
      reset: passwordRecoverySolicitateReset,
      data: passwordRecoverySolicitateData,
    },
  ] = usePasswordRecoverySolicitateMutation();

  const formValidationSchema = yup.object({
    email: yup
      .string()
      .required("Digite seu email")
      .email("Formato de email inválido"),
  });

  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: formValidationSchema,
    onSubmit: async (values) => {
      try {
        await passwordRecoverySolicitate({
          email: values.email,
        });
        form.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div className="password-recovery">
      <div className="header">
        <img src={Logo} alt="Logo Assis" />
        <Divider className="divider" />
      </div>
      <div className="main">
        <Paper className="content" elevation={4}>
          <form noValidate autoComplete="off" onSubmit={form.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  Recuperar senha
                </Typography>
                <br></br>
                {passwordRecoverySolicitateData && (
                  <Alert
                    severity="success"
                    onClose={() => {
                      form.resetForm();
                      passwordRecoverySolicitateReset();
                    }}
                  >
                    {passwordRecoverySolicitateData.message}
                  </Alert>
                )}

                {passwordRecoverySolicitateIsError && (
                  <Alert
                    severity="error"
                    onClose={() => {
                      passwordRecoverySolicitateReset();
                    }}
                  >
                    {RTKFetchError(passwordRecoverySolicitateError)?.message}
                  </Alert>
                )}
                <br></br>
                <Typography variant="body1">
                  Para criar uma nova senha, primeiro confirme o endereço de
                  e-mail de recuperação.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="Email"
                  placeholder="seu@email.com"
                  size="small"
                  fullWidth
                  margin="dense"
                  disabled={passwordRecoverySolicitateIsLoading}
                  InputProps={{
                    startAdornment: <MailIcon fontSize="small" />,
                  }}
                  value={form.values.email}
                  onChange={form.handleChange}
                  error={form.touched.email && Boolean(form.errors.email)}
                  helperText={form.touched.email && form.errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={passwordRecoverySolicitateIsLoading}
                >
                  Enviar
                  {passwordRecoverySolicitateIsLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                      }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>

          <Grid container spacing={2} className="footer">
            <Grid item xs={12}>
              <Button variant="text" onClick={() => navigate("/")}>
                Voltar pra tela de login
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                Ao continuar, estou de acordo com os{" "}
                <a href="#">Termos de Uso e Política de Privacidade</a> do
                Assis.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
