import React, { CSSProperties, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Alert,
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import ContinueIcon from "@mui/icons-material/ArrowForward";
import { ResponseError } from "../../types";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./styles.sass";
import { width } from "@mui/system";
import {
  useDeleteInstanceMutation,
  useGetMeQuery,
} from "../../services/AssisApi";
import { RTKFetchError } from "../../services/Helpers";

interface Props {
  style?: CSSProperties;
}

export default function InstanceDeleteConfirm({ style }: Props) {
  const navigate = useNavigate();
  const { instanceId } = useParams();
  // const Location = useLocation();

  const { data: me, error: meError, isLoading: meIsLoading } = useGetMeQuery();
  const [
    deleteInstance,
    {
      isLoading: deleteInstanceIsLoading,
      isError: deleteInstanceIsError,
      data: deleteInstanceData,
      error: deleteInstanceError,
      reset: deleteInstanceReset,
    },
  ] = useDeleteInstanceMutation();

  // const [step, setStep] = useState(1);
  // const [feedbackOption, setFeedbackOption] = useState(0);

  // const [loading, setLoading] = useState(false);
  // const [response, setResponse] = useState('');
  // const [error, setError] = useState<ResponseError>({} as ResponseError);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Digite um email valido")
      .equals([me?.email], "Email incorreto")
      .required("Informe seu email para a confirmação"),
  });

  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await deleteInstance(instanceId as string);
        form.resetForm();
        navigate("/i");
      } catch (error) {
        console.log(error);
      }
    },
  });

  const onClose = () => {
    navigate(-1);
    window.location.reload();
  };

  const onCancel = () => {
    form.resetForm();
    navigate(-1);
  };

  // const deleteInstanceHandler = () => {
  //   alert(form.values.email);
  // };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      className="user-delete-confirm-screen"
    >
      {/* {step === 1 && ( */}
      <DialogContent className="content">
        <Typography variant="h6">Apagar ambiente</Typography>
        <Typography variant="subtitle2">Ao apagar o ambiente:</Typography>
        <ul>
          <li>
            <Typography variant="subtitle1">
              Todas as análises serão apagadas
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              Os resultados de todas as suas análises serão apagados
            </Typography>
          </li>
        </ul>

        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={form.handleSubmit}
        >
          {deleteInstanceData && (
            <Alert
              severity="success"
              onClose={() => {
                form.resetForm();
                deleteInstanceReset();
              }}
            >
              {deleteInstanceData.message}
            </Alert>
          )}
          {deleteInstanceIsError && (
            <Alert
              severity="error"
              onClose={() => {
                deleteInstanceReset();
              }}
            >
              {RTKFetchError(deleteInstanceError)?.message}
            </Alert>
          )}

          <Typography variant="subtitle2" className="alert-text">
            Esta ação não pode ser desfeita! Digite seu
          </Typography>
          <TextField
            name="email"
            hiddenLabel
            placeholder="Insira seu email para continuar"
            size="small"
            fullWidth
            disabled={deleteInstanceIsLoading}
            value={form.values.email}
            onChange={form.handleChange}
            error={form.touched.email && Boolean(form.errors.email)}
            helperText={form.touched.email && form.errors.email}
          />
          <div className="footer">
            <Button
              className="button"
              onClick={onCancel}
              variant="contained"
              disabled={deleteInstanceIsLoading}
            >
              Cancelar
            </Button>
            <Button
              className="button"
              color="error"
              variant="contained"
              type="submit"
              disabled={deleteInstanceIsLoading}
            >
              Excluir
              {deleteInstanceIsLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                  }}
                />
              )}
            </Button>
          </div>
        </Box>
      </DialogContent>
      {/* )} */}
    </Dialog>
  );
}
