import Screen from "../../components/Screen";
import "./styles.sass";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import BatchUpload from "../../components/BatchUpload";
import { useState } from "react";
import { Instance } from "../../types";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

interface Props {}

export default function NewAnalysis({}: Props) {
  const [open, setOpen] = useState(false);
  const selectedInstance: Instance = useSelector(
    (state: RootState) => state.app.selectedInstance
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="new-analysis-screen">
      <Screen>
        <Typography variant="h6" className="title">
          {selectedInstance.description}
        </Typography>

        <div className="main">
          <div className="file-upload-wrapper">
            <BatchUpload handleOpen={handleOpen} />
          </div>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            Entre em contato com o time do Assis pelo email abaixo
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="modal-modal-description">
              Email: contato@assis.ai
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Screen>
    </div>
  );
}
