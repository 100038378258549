import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import ContinueIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

import "./styles.sass";
import { useCreateInstanceMutation } from "../../services/AssisApi";
import { RTKFetchError } from "../../services/Helpers";
// interface Props {
//   style?: CSSProperties;
// }

export default function NewInstance() {
  const navigate = useNavigate();

  const [createInstance, { isLoading, isError, data, error, reset }] =
    useCreateInstanceMutation();

  const validationSchema = yup.object({
    description: yup
      .string()
      .required("Informe a descrição do ambiente")
      .min(3, "O nome do ambiente deve conter pelo menos ${min} caracteres")
      .max(25, "O nome do ambiente deve conter no máximo ${max} caracteres"),
  });

  const form = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await createInstance({
          description: values.description,
        });
        form.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const onClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (data?.instance._id)
      navigate(`/i/${data?.instance._id}/analises`, {
        state: { pageTitle: data?.instance.description },
      });
  }, [data]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      className="new-instance"
    >
      <DialogContent className="content">
        <Typography variant="h6" className="title">
          Vamos começar!
        </Typography>

        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={form.handleSubmit}
        >
          {data && (
            <Alert severity="success" onClose={() => navigate(-1)}>
              {data.message}
            </Alert>
          )}
          {isError && (
            <Alert
              severity="warning"
              onClose={() => {
                reset();
              }}
            >
              {RTKFetchError(error)?.message}
            </Alert>
          )}

          <br />

          <TextField
            name="description"
            label="Insira o nome do ambiente"
            placeholder="Insira o nome do ambiente"
            size="small"
            fullWidth
            inputProps={{
              minLength: 3,
              maxLength: 25,
            }}
            disabled={isLoading}
            value={form.values.description}
            onChange={form.handleChange}
            error={form.touched.description && Boolean(form.errors.description)}
            helperText={form.touched.description && form.errors.description}
          />
          <div className="footer">
            <Button type="submit" variant="contained" disabled={isLoading}>
              Continuar
              <ContinueIcon />
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                  }}
                />
              )}
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
