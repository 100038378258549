import { CSSProperties } from "react";
import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MastercardIcon from "./images/mastercard.png";

import "./styles.sass";

interface Props {
  style?: CSSProperties;
}

export default function DeleteCard({}: Props) {
  const navigate = useNavigate();

  const onClose = () => {
    navigate(-1);
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" className="delete-card">
      <DialogContent className="content">
        {/* {response && (
            <Alert
              severity="success"
              variant="filled"
              onClose={() => setResponse("")}
            >
              {response}
            </Alert>
          )}
          {error.message && (
            <Alert
              severity="error"
              variant="filled"
              onClose={() => setError({} as ResponseError)}
            >
              {error.message}
            </Alert>
          )} */}
        <Typography variant="h6" className="title">
          Remover forma de pagamento?
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Depois de remover a forma de pagamento, não será mais possível
              usa-la. Para isso você precisará adicioná-la novamente
            </Typography>
          </Grid>
          <Grid item xs={12} className="card-block-info">
            <img className="icon" src={MastercardIcon} alt="Logo do cartão" />
            <Typography variant="subtitle2" className="description">
              Mastercard ****4567
            </Typography>
          </Grid>
        </Grid>

        <div className="footer">
          <Button
          // disabled={loading}
          >
            Cancelar
            {/* {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                  }}
                />
              )} */}
          </Button>
          <Button
            type="submit"
            variant="contained"
            // disabled={loading}
          >
            Confirmar
            {/* {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                  }}
                />
              )} */}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
