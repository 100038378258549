import { CSSProperties } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MastercardIcon from "./images/mastercard.png";
import CardIcon from "@mui/icons-material/CreditCard";

import "./styles.sass";

interface Props {
  style?: CSSProperties;
}

export default function EditCard({}: Props) {
  const navigate = useNavigate();

  const validationSchema = yup.object({
    cardNumber: yup
      .string()
      .length(16, "Número do cartão inválido")
      .required("Informe número do cartão"),
    name: yup
      .string()
      .min(3, "O nome do cartão deve conter pelo menos ${min} caracteres")
      .max(10, "O nome do cartão deve conter no máximo ${max} caracteres")
      .required("Informe número do cartão"),
    expirationMounth: yup
      .number()
      .typeError("Inválido")
      // .length(2, "Inválido")
      .required("Obrigatório"),
    expirationYear: yup.number().typeError("Inválido").required("Obrigatório"),
    cvv: yup.number().typeError("Inválido").required("Obrigatório"),
  });

  const form = useFormik({
    initialValues: {
      cardNumber: "",
      expirationMounth: "",
      expirationYear: "",
      cvv: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // navigate(-1);
    },
  });

  const onClose = () => {
    navigate(-1);
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" className="edit-card">
      <DialogContent className="content">
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={form.handleSubmit}
        >
          {/* {response && (
            <Alert
              severity="success"
              variant="filled"
              onClose={() => setResponse("")}
            >
              {response}
            </Alert>
          )}
          {error.message && (
            <Alert
              severity="error"
              variant="filled"
              onClose={() => setError({} as ResponseError)}
            >
              {error.message}
            </Alert>
          )} */}
          <Typography variant="h6" className="title">
            <CardIcon className="icon" /> Editar forma de pagamento
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} className="card-block-info">
              <img className="icon" src={MastercardIcon} alt="Logo do cartão" />
              <Typography variant="subtitle2" className="description">
                Mastercard ****4567
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name="expirationMounth"
                label="MM"
                placeholder="MM"
                size="small"
                sx={{ width: 80 }}
                inputProps={{
                  minLength: 2,
                  maxLength: 2,
                }}
                // disabled={loading}
                value={form.values.expirationMounth}
                onChange={form.handleChange}
                error={
                  form.touched.expirationMounth &&
                  Boolean(form.errors.expirationMounth)
                }
                helperText={
                  form.touched.expirationMounth && form.errors.expirationMounth
                }
              />
              <Typography variant="h5" className="expiration-separate">
                /
              </Typography>
              <TextField
                name="expirationYear"
                label="YY"
                placeholder="YY"
                size="small"
                sx={{ width: 80 }}
                inputProps={{
                  minLength: 2,
                  maxLength: 2,
                }}
                // disabled={loading}
                value={form.values.expirationYear}
                onChange={form.handleChange}
                error={
                  form.touched.expirationYear &&
                  Boolean(form.errors.expirationYear)
                }
                helperText={
                  form.touched.expirationYear && form.errors.expirationYear
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="cvv"
                label="CVV"
                placeholder="CVV"
                size="small"
                fullWidth
                inputProps={{
                  minLength: 3,
                  maxLength: 3,
                }}
                // disabled={loading}
                value={form.values.cvv}
                onChange={form.handleChange}
                error={form.touched.cvv && Boolean(form.errors.cvv)}
                helperText={form.touched.cvv && form.errors.cvv}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nome para identificação"
                placeholder="Ex.: Itaú card"
                size="small"
                fullWidth
                inputProps={{
                  minLength: 3,
                  maxLength: 10,
                }}
                // disabled={loading}
                value={form.values.cardNumber}
                onChange={form.handleChange}
                error={
                  form.touched.cardNumber && Boolean(form.errors.cardNumber)
                }
                helperText={form.touched.cardNumber && form.errors.cardNumber}
              />
            </Grid>
          </Grid>

          <div className="footer">
            <Button
            // disabled={loading}
            >
              Cancelar
              {/* {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                  }}
                />
              )} */}
            </Button>
            <Button
              type="submit"
              variant="contained"
              // disabled={loading}
            >
              Atualizar
              {/* {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                  }}
                />
              )} */}
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
