import {
  Avatar,
  Badge,
  Button,
  ButtonProps,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";

import "./styles.sass";
import {
  Notifications as NotificationsIcon,
  Help as HelpIcon,
  LogoutOutlined as LogoutIcon,
} from "@mui/icons-material";
import { MouseEvent, useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/LocalStorage";
import { useGetMeQuery } from "../../services/AssisApi";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

interface Props extends ButtonProps {}

export default function MainHeader({}: Props) {
  const [userMenuEl, setUserMenuEl] = useState<null | HTMLElement>(null);

  const [token, setToken, removeToken] = useLocalStorage("token");
  // const { data: me, error: meError, isLoading: meIsLoading } = useGetMeQuery();
  const me = useSelector((state: RootState) => state.auth.authenticatedUser);

  const logout = () => {
    removeToken();
    window.location.reload();
  };

  // useEffect(() => {
  //   if (meError) {
  //     removeToken();
  //     window.location.reload();
  //   }
  // }, [meError]);

  return (
    <Paper elevation={3} className="main-header">
      <Typography variant="h6">
        Bem-vindo {me?.name?.split(" ")[0]}! 👋🏼
      </Typography>
      <div className="menu">
        {/* <IconButton color="primary">
          <HelpIcon fontSize="small" />
        </IconButton>
        <IconButton id="header-notification" color="primary">
          <Badge color="error" variant="dot" invisible={false}>
            <NotificationsIcon fontSize="small" />
          </Badge>
        </IconButton> */}
        <IconButton
          onClick={(event: MouseEvent<HTMLElement>) => {
            setUserMenuEl(event.currentTarget);
          }}
        >
          <Avatar
            sizes="small"
            alt={me?.name?.split(" ")[0]}
            src={me?.picture}
          ></Avatar>
        </IconButton>
      </div>
      <Menu
        anchorEl={userMenuEl}
        open={Boolean(userMenuEl)}
        onClose={() => setUserMenuEl(null)}
      >
        <MenuItem disabled>
          <ListItemText>{me?.email}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>
    </Paper>
  );
}
