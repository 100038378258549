import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { ResponseError } from "../types";

export function isFetchBaseQueryError(error: unknown) {
  return typeof error === "object" && error != null && "status" in error;
}

export function RTKFetchError(
  error: FetchBaseQueryError | SerializedError | undefined
): ResponseError | undefined {
  if (error && "status" in error) return error.data as ResponseError;
  return undefined;
}
